import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { OTPInputComponent } from "../components/OTPInput/Index";
import { Container } from "react-bootstrap";
import { Form, Button } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { changeAuthData, onlineUser } from "store/authorization";
import {
  enterUserAccount,
  forgetThePinCode,
  logoutUserAccount,
  verifyParentalCode,
} from "../network";
import amuseLogo from "assets/images/amuse-logo.svg";
import ROUTES from "constants/_routes";
import { changeLang, changeLangOnPayload } from "store/lang";
import i18next from "i18next";
import Spinner from "modules/Shared/Spinner";

function VerifyParentalCode() {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const { phone } = authorization;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const otpRef = useRef();

  const { mode, userData, from } = location?.state;
  console.log("🚀 ~ VerifyParentalCode ~ mode:", mode);
  // useEffect(() => {
  //   if (!location?.state?.userData) {
  //     navigate(ROUTES.WATCHERS);
  //   }
  // }, [location?.state?.userData, navigate]);
  const otpInputRef = useRef();

  useEffect(() => {
    if (isSubmitted && code.length !== 4) {
      setError(true);
    } else {
      setError(false);
    }
  }, [code.length, isSubmitted]);

  function enterCode() {
    setIsSubmitted(true);

    if (code?.length === 4) {
      setError(false);
      const payload = {
        code,
      };

      setLoading(true);
      verifyParentalCode(
        payload,
        (success) => {
          setLoading(false);
          toastSuccess(success.msg);
          dispatch(onlineUser(userData));
          const accountAppLanguage = userData?.accountAppLanguage;
          if (accountAppLanguage) {
            i18next.changeLanguage(accountAppLanguage);
            dispatch(changeLangOnPayload(accountAppLanguage));
          }
          if (mode === "add") {
            const enterUserAccountPayload = {
              accountId: userData._id,
              deviceId: authorization.deviceId,
              deviceName: authorization.browserName,
              userToken: authorization.token,
            };

            enterUserAccount(
              enterUserAccountPayload,
              (success) => {
                dispatch(changeAuthData({ isEnteredUserAccount: true }));
                setTimeout(() => {
                  navigate(ROUTES.ADD_CHILDS, {
                    state: {
                      mode,
                      isParent: false,
                      from,
                    },
                  });
                }, 0);
              },
              (fail) => {
                toastError(fail?.data?.err_msg);
              }
            );
          }

          if (mode === "enter") {
            if (authorization?.online?._id) {
              const payload = {
                deviceId: authorization.deviceId,
                userId: authorization?._id,
                accountId: authorization?.online?._id,
              };
              logoutUserAccount(
                payload,
                (success) => {
                  navigate(ROUTES.HOME, {
                    state: {
                      splashScreen: true,
                      selectedUser: true,
                      mode,
                      online: userData,
                    },
                  });
                },
                (fail) => {}
              );
            } else {
              navigate(ROUTES.HOME, {
                state: {
                  splashScreen: true,
                  selectedUser: true,
                  mode,
                  online: userData,
                },
              });
            }
          }
        },
        (fail) => {
          setLoading(false);
          otpInputRef.current.resetInput();
          if (fail.data) toastError(fail.data.err_msg);
          else toastError(t("errorPinCode"));
        }
      );
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    if (code.length === 4) {
      enterCode();
    }
  }, [code]);

  const handleForgetPinCode = () => {
    setIsSpinnerLoading(true);
    forgetThePinCode(
      (success) => {
        if (success?.isSuccess && success?.data?.isSend) {
          setIsSpinnerLoading(false);
          navigate(ROUTES.FORGETPINCODE, { state: { phone } });
        } else {
          toastError(success.err_msg);
          setIsSpinnerLoading(false);
        }
      },
      (fail) => {
        if (fail.data.err_msg) {
          toastError(fail.data.err_msg);
          setIsSpinnerLoading(false);
        } else {
          toastError();
          setIsSpinnerLoading(false);
        }
      }
    );
  };

  return (
    <>
      {isSpinnerLoading && <Spinner />}
      <section className="main-section home-section PartenalCode">
        <Container>
          <div style={{ width: "70%", margin: "auto" }}>
            <div style={{ width: "60%", margin: "auto" }}>
              {/* <div className="single-centered-logo mt-5">
                <img src={amuseLogo} alt="amuse the kids" />
              </div> */}
              <div className="single-centered-title text-center mt-5">
                {t("pinCode")}
              </div>
              <div className="single-centered-subtitle text-center">
                {t("enterPinCode")}
              </div>

              <div className="otp__container  text-center">
                <div className="d-flex justify-content-center" dir="ltr">
                  <OTPInputComponent
                    ref={otpInputRef}
                    length={4}
                    isNumberInput
                    autoFocus
                    isSecure
                    className="otpContainer"
                    inputClassName="otpInput"
                    hasErrored={error}
                    errorStyle={{
                      border: "1px solid red",
                    }}
                    onChangeOTP={(pin) => {
                      setCode(pin);
                    }}
                  />
                </div>
              </div>
              <div
                className="otp__errorMessage"
                style={{
                  visibility: error ? "visible" : "hidden",
                  transition: "all 0.5s ease-in-out",
                  opacity: error ? 1 : 0,
                  textAlign: "start",
                }}
              >
                {t("enterValidCode")}
              </div>
              {mode !== "enter" && (
                <div className="pay__button-primary--cent">
                  <Button
                    loading={loading}
                    className="button-primary parentalCode-btn"
                    onClick={enterCode}
                  >
                    {t("confirm")}
                  </Button>
                </div>
              )}
              <div className="forget-pin-code text-center my-2 ">
                <span
                  role={"button"}
                  onClick={handleForgetPinCode}
                  className="span"
                >
                  {t("forgetPinCode")}
                </span>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default VerifyParentalCode;
