import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { hasSubscription } from "modules/TpaySubscription/network";
import ROUTES from "constants/_routes";
import { useSelector } from "react-redux";

function JoinToCompetitionModal({ isModalVisible, onCancel }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const phoneNumberPattern = /^(011|012|010|015)\d{8}$/;
  const [disableJointButton, setDisableJointButton] = useState(false);
  const { lang, dir } = useSelector((state) => state.lang);
  const [loading, setLoading] = useState(false);
  const handleCloseModal = () => {
    onCancel();
    setPhoneNumberError("");
  };
  useEffect(() => {
    if (!isModalVisible) {
      setPhoneNumber(""); // Reset phoneNumber to an empty string when the modal closes
    }
  }, [isModalVisible]);

  const phoneNumberhandler = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");

    setPhoneNumber(value);

    // Check if the entered phone number is valid in real-time
    if (value.length === 11) {
      if (phoneNumberPattern.test(value)) {
        setPhoneNumberError(""); // Clear the error message
        setIsPhoneNumberValid(true); // Phone number is valid
      } else {
        setPhoneNumberError("Enteravalidnumberstartingwith011012010or015");

        setIsPhoneNumberValid(false);
      }
    } else {
      setPhoneNumberError("Phonenumbermustbeexactly11digits");
      setIsPhoneNumberValid(false);
    }
  };

  const handleJoinButtonClick = () => {
    if (phoneNumber.length === 11 && phoneNumberPattern.test(phoneNumber)) {
      setLoading(true);
      // Phone number is valid, you can proceed with your logic
      let phoneNumberWithCode = "2" + phoneNumber;
      // setDisableJointButton(true);
      hasSubscription(
        phoneNumberWithCode,
        (success) => {
          if (success.data.hasTpaySubscription) {
            navigate(ROUTES.GIFTS, {
              state: {
                phoneNumberWithCode: phoneNumberWithCode,
              },
            });

            onCancel();
            setLoading(false);
            // setDisableJointButton(false);
          } else {
            setLoading(false);
            navigate(ROUTES.TPAYSUBSCRIPTION);
            setPhoneNumber("");
            onCancel();
            // setDisableJointButton(false);
          }
        },
        (fail) => {
          setLoading(false);

          // navigate(ROUTES.TPAYSUBSCRIPTION);
          setPhoneNumber("");
          onCancel();
          // setDisableJointButton(false);
        }
      );
    } else {
      setPhoneNumberError("Phonenumbermustbeexactly11digits");
      setLoading(false);

      setIsPhoneNumberValid(false);
      // setDisableJointButton(false);
    }
  };

  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={handleCloseModal}
      className="joinCompetition-modal modal-container text-center"
      footer={false}
      closeIcon={
        // openFromGifts ? (
        <img src={closeIcon} alt="icon" />
        // ) : (
        // <p>&nbsp;</p>
        // )
      }
    >
      <h2
        className="text-primary-header text-primary-header--joincompetition "
        style={{ marginBottom: "2.5rem" }}
      >
        {t("Enteryourmobilenumbertoconfirmjoiningthecompetition")}
      </h2>
      <div className="modal-input-container">
        <input
          type="text"
          id="MSISDN"
          className={`selector_container-phoneinput ${
            phoneNumberError
              ? "error-input transition-border"
              : "transition-border"
          }`}
          style={{ borderColor: "#356ab3" }}
          value={phoneNumber}
          onChange={phoneNumberhandler}
          placeholder="01XXXXXXXXX"
          pattern="[0-9]*" // This restricts input to numbers only
          inputMode="numeric"
        />
        {phoneNumberError ? (
          <p
            className="error-message--joinCompetition"
            style={{ display: "flex", direction: dir }}
          >
            {" "}
            {t(phoneNumberError)}
          </p>
        ) : (
          <p className="error-message--joinCompetition">&nbsp;</p>
        )}
        <div className=" text-center m-auto">
          <Button
            loading={loading}
            className="subscribe-btn subscribe-btn_joinCompetition"
            onClick={handleJoinButtonClick}
            // disabled={disableJointButton}
          >
            {t("Join")}
          </Button>
          {/* <Button
            className='subscribe-btn'
            disabled={disableJointButton}
            onClick={handleJoinButtonClick}
          >
            Join
          </Button> */}
        </div>
      </div>
    </Modal>
  );
}

export default JoinToCompetitionModal;
