import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import AmuseLogo from "assets/images/amuse-logo.svg";
import ROUTES from "constants/_routes";
import checkedRadioButton from "assets/icons/checkedRadioButton.svg";
import unCheckedRadioButton from "assets/icons/unCheckedRadioButton.svg";
import boyImage from "assets/icons/boyImage.svg";
import girlImage from "assets/icons/girlImage.svg";

const SelectGender = () => {
  const [gender, setGender] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { mode, from } = location.state;

  // useEffect(() => {
  //   if (isBoy !== null) {
  //     navigate(ROUTES.SELECT_AVATAR, {
  //       state: {
  //         isBoy,
  //         isParent: false,
  //         mode: location?.state?.mode,
  //       },
  //     });
  //   } //eslint-disable-next-line
  // }, [isBoy, navigate]);

  function handleSelectGender(gender) {
    setGender(gender);
    setTimeout(() => {
      navigate(ROUTES.SELECT_AVATAR, {
        state: {
          isBoy: gender === "boy" ? true : false,
          isParent: false,
          mode,
          from,
        },
      });
    }, 500);
  }

  return (
    <section className="select-gender mt-5">
      {/* <figure className="single-centered-logo text-center ">
        <img src={AmuseLogo} alt="amuse the kids" />
      </figure> */}

      <div className="select-gender__title">
        {/* {mode === "edit" ? t("editAvatar") : t("chooseAvatar")} */}
        {t("chooseGender")}
      </div>

      {/* <div className="select-gender__title">{t("youAreA")}</div> */}

      <div className="select-gender__radios">
        <Space size={50} className="select-gender__antd-space">
          <div className="select-gender__item">
            <Space size={30} className="select-gender__radio">
              <img
                src={
                  gender === "girl" ? checkedRadioButton : unCheckedRadioButton
                }
                alt="girl"
                role={"button"}
                onClick={() => handleSelectGender("girl")}
              />
              <figure
                className="select-gender__figure"
                role={"button"}
                onClick={() => handleSelectGender("girl")}
              >
                <img src={girlImage} alt="girl" />
                <figcaption className="select-gender__fig-caption  my-3">
                  {t("girl")}
                </figcaption>
              </figure>
            </Space>
            <div className="select-gender__image"></div>
          </div>

          <div className="select-gender__item">
            {" "}
            <Space size={30} className="select-gender__radio">
              <img
                src={
                  gender === "boy" ? checkedRadioButton : unCheckedRadioButton
                }
                alt="boy"
                role={"button"}
                onClick={() => handleSelectGender("boy")}
              />
              <figure
                className="select-gender__figure"
                role={"button"}
                onClick={() => handleSelectGender("boy")}
              >
                <img src={boyImage} alt="boy" />
                <figcaption className="select-gender__fig-caption  my-3">
                  {t("boy")}
                </figcaption>
              </figure>
            </Space>
          </div>
        </Space>

        {/* <Radio.Group onChange={(value) => changeGender(value)}>
          <Radio value={true} className="item">
            {t("boy")}
          </Radio>
          <Radio value={false} className="item">
            {t("girl")}
          </Radio>
        </Radio.Group> */}
      </div>
    </section>
  );
};

export default SelectGender;
