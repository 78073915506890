import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ChoosePlan from "./models/ChoosePlan/ChoosePlan";
import Payment from "./models/Payment/Payment";

function Subscription() {
  const [mode, updateMode] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const mode = location.search.split("page=").length
      ? location.search.split("page=")[1]?.split("&")[0]
      : null;
    updateMode(mode);
  }, [location]);

  return (
    <div className="main-section subscription">
      {mode === "payment" ? <Payment /> : <ChoosePlan />}
    </div>
  );
}

export default Subscription;
