import checkedWishListButton from "assets/icons/checked-wish-list.svg";
import closeIcon from "assets/icons/close.svg";
import downloading from "assets/icons/downloadingIcon.json";
import playButton from "assets/icons/play-button.svg";
import addWishlistButton from "assets/icons/plus-button.svg";
import ROUTES from "constants/_routes";
import { createBackgroundImage } from "helpers/createBackgoundImageUrl";
import { toastError, toastSuccess } from "helpers/toasters";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { addDeleteWishList } from "./netwok";

import { Modal } from "antd";
import { addDataToIndexDB, dbInfo } from "helpers/indexdb";
import useDeleteVideo from "modules/MyDownloads/useDeleteVideo";
import useIsDownloaded from "modules/MyDownloads/useIsDownloaded";
import SubscripeNowModal from "modules/Subscription/models/SubscripeNowModal/SubscripeNowModal";
import DownloadProgress from "modules/WatchNow/components/DownloadProgress";
import { baseUrl } from "services";
import binIcon from "../../assets/icons/binIcon.svg";
import downloadIcon from "../../assets/icons/downloadIcon.svg";
import freeEN from "../../assets/images/free badge.png";
import freeAr from "../../assets/images/free badgeAr.png";
import lock from "../../assets/images/lock.svg";
import SubscribenowModal from "modules/WatchNow/components/SubscribenowModal";
import { max } from "moment";

const VideoListItem = ({
  getAllWishListVideos,
  details: {
    header: {
      headerImagePath,
      imgPath,
      title,
      duration,
      isWished,
      _id: headerId,
      isSeries,
      videoId,
      category,
      genre,
      isFree,
      // isDownloaded,
      hasFreeVideo,
      firstEpisodeId,
    },
    isContinueWatching,

    // genreName,
    // categoryName,
  },
  isOffline,
  downloadedVideoData,
  updateDownloadedList,
}) => {
  const [percentage, setPercentage] = useState(0);
  const [fileInfo, setfileInfo] = useState("");
  const [isWishList, setIsWishList] = useState(isWished);
  const [isDownloadHov, updateIsDownloadHov] = useState(false);
  const [showDownloadingModal, setShowDownloadingModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isConnectedToInternet, setIsConnectedToInternet] = useState(
    navigator.onLine
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authorization } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.lang);

  const engLng = lang === "en" ? true : false;

  const updateProgressFiles = (percentage, fileInfo) => {
    setPercentage(percentage);
    setfileInfo(fileInfo);
  };
  const [isSubscribeNowModalVisible, updateIsSubscribeNowModalVisible] =
    useState(false);
  const location = useLocation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: downloading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const isPaid = authorization?.isPaid?.isPaid;
  const isAuth = authorization?.token;
  const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");
  const addTokenToMedia = (path, type) => {
    var token = authorization?.token;
    return baseUrl + path + "?token=" + token;
  };

  // const {
  //   isDownloaded,
  //   updateDownloadedState, // Return the update function
  // } = useSyncDeleteDownloads(authorization, videoId);

  const { deleteVideo, isDeleted } = useDeleteVideo(authorization, videoId);
  const {
    isDownloaded,
    updateDownloadedState, // Return the update function
  } = useIsDownloaded(authorization, videoId);

  useEffect(() => {
    if (updateDownloadedList) updateDownloadedList();
  }, [isDeleted]);

  const forceUpdateWishList = () => {
    if (location.pathname == "/my-list") setIsWishList(true);
  };

  const handleDeleteVideo = (e) => {
    e.stopPropagation();
    deleteVideo();
    // updateDownloadedState();
  };
  const downloadVideoHandler = (e) => {
    e.stopPropagation();
    if (!isDownloaded) {
      setShowDownloadingModal(true);
      setIsDownloading(true);

      let videoData = {
        videoId: downloadedVideoData.videoId,
        videoHeaderId: downloadedVideoData.videoHeaderId,
        audios: [
          {
            path: urlReplacement(addTokenToMedia(downloadedVideoData.audio)),
            languageName: downloadedVideoData.audioLanguage,
          },
        ],
        videoPath: [
          [
            "imgPath",
            urlReplacement(addTokenToMedia(downloadedVideoData.imgPath)),
          ],
          [
            "audioPath",
            urlReplacement(addTokenToMedia(downloadedVideoData.audio)),
          ],
          [
            "videoPath",
            urlReplacement(addTokenToMedia(downloadedVideoData.videoPath)),
          ],
        ],
        imgPath: urlReplacement(addTokenToMedia(downloadedVideoData.imgPath)),
        category: downloadedVideoData.category,
        genre: downloadedVideoData.genre,
        title: downloadedVideoData.title,
      };
      if (downloadedVideoData.subtitle) {
        videoData.videoPath.push([
          "subtitlePath",
          addTokenToMedia(downloadedVideoData.subtitle),
        ]);

        videoData.subtitles = [
          {
            path: urlReplacement(addTokenToMedia(downloadedVideoData.subtitle)),
            languageName: downloadedVideoData.subtitleLanguage,
          },
        ];
      }

      addDataToIndexDB(
        authorization.online._id,
        dbInfo.dbVersion,
        videoData,
        () => {
          setIsDownloading(false);
          updateDownloadedList();
          setShowDownloadingModal(false);
          setIsDownloading(false);
          updateDownloadedState();
          toastSuccess(t("downloadingSuccess"));
        },
        authorization.online._id,
        updateProgressFiles
      );
    }
  };
  const closeDownloadModalHanlder = (e) => {
    e.stopPropagation();
    setShowDownloadingModal(false);
  };
  const onDownloadMouseEnter = () => {
    updateIsDownloadHov(true);
  };
  const onDownloadMouseLeave = () => {
    updateIsDownloadHov(false);
  };
  function addToWishList(e) {
    e.stopPropagation();
    const payload = {
      accountId: authorization?.online?.id,
      videoId: headerId,
      isDeleted: isWishList,
    };
    if (authorization?.online?.id) {
      addDeleteWishList(
        payload,
        () => {
          setIsWishList(!isWishList);
          getAllWishListVideos();
          forceUpdateWishList();
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    } else {
      updateIsSubscribeNowModalVisible(true);
      document.body.style.overflow = "hidden";
    }
  }

  function watchNow(e, showFullScreen = false) {
    e.stopPropagation();
    let qpData = [
      headerId,
      videoId || firstEpisodeId,
      isSeries ? 1 : 0,
      showFullScreen,
    ].join("-");

    let qp = {
      qp: qpData,
    };

    if (isOffline) {
      qp.isOffline = isOffline;
    }
    if (
      (isAuth && hasFreeVideo && !isContinueWatching) ||
      (isAuth && isPaid) ||
      (isAuth && isContinueWatching && isFree)
    ) {
      navigate({
        pathname: ROUTES.WATCH_NOW,
        search: `?${createSearchParams(qp)}`,
      });

      document.body.style.overflow = "";
    } else {
      updateIsSubscribeNowModalVisible(true);
      document.body.style.overflow = "hidden";
    }
  }

  // const freeBadges =
  //   (isContinueWatching && isFree) || (!isContinueWatching && hasFreeVideo)
  //     ? true
  //     : false;

  return (
    <BackgroundImage
      wrapperClassName={`video-list-item`}
      // wrapperClassName={`video-list-item ${
      //   hasFreeVideo && !isPaid && !isContinueWatching
      //     ? `video-list-item-has-free-video-${lang}`
      //     : null
      // }`}
      src={createBackgroundImage(imgPath || headerImagePath)}
      // src={createBackgroundImage(imgPath, isOffline)}
      onClick={(e) => watchNow(e)}
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        objectFit: "cover",
        // maxWidth: "100%",
        // maxHeight: "100%",
        aspectRatio: "4/3",
      }}
    >
      {/* Free badge */}
      {isFree && !isPaid && isContinueWatching && (
        <p
          style={{
            marginRight: "5px",
            marginLeft: "auto",
            position: "relative",
            top: "5px",
            height: "max-content",
            width: "fit-content",
          }}
        >
          <img
            src={engLng ? freeEN : freeAr}
            alt=""
            style={{
              height: "32px",
              width: "32px",
              display: "block",
            }}
          />
        </p>
      )}

      {!isFree && !isPaid && isContinueWatching && (
        <>
          <img src={lock} alt="LOCK" className="video-lock-icon" />
          <span className="video-lock-overlay"></span>
        </>
        // <div className="video-lock-icon">
        // </div>
      )}

      {hasFreeVideo && !isPaid && !isContinueWatching && (
        <p
          style={{
            marginRight: "5px",
            marginLeft: "auto",
            position: "relative",
            top: "5px",
            height: "max-content",
            width: "fit-content",
          }}
        >
          <img
            src={engLng ? freeEN : freeAr}
            alt=""
            style={{
              height: "32px",
              width: "32px",
              display: "block",
            }}
          />
        </p>
      )}

      <div
        className={`video-list-item${
          location.pathname !== "/my-downloads"
            ? "__overlay"
            : "__overlay--downloads"
        }`}
      >
        <header style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
          {/* {location.pathname === "/my-downloads" && navigator.onLine && (
            <div
              className='video-list-item__download-icon'
              onClick={isDownloaded ? handleDeleteVideo : downloadVideoHandler}
            >
              <img
                src={isDownloaded ? binIcon : downloadIcon}
                alt='download'
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          )} */}
          <h6
            className={`video-list-item${
              location.pathname !== "/my-downloads"
                ? "__title"
                : "__title--downloads"
            }`}
          >
            {title}
          </h6>
        </header>
        <div className="video-list-item__details">
          <ul className="video-list-item__cats">
            {duration && <li> {duration}</li>}
            {genre && <li className="video-list-item__cats__item ">{genre}</li>}
            {category && (
              <li className="video-list-item__cats__item ">{`${
                category?.length > 15 ? category.slice(0, 15) + "..." : category
              }`}</li>
            )}
          </ul>
        </div>
        {/* <div className='video-list-item__controllers flex-wrap justify-content-around'> */}
        <div className="video-list-item__controllers flex-wrap justify-content-between">
          {/* {!isOffline && location.pathname !== "/my-downloads" && ( */}
          {!isOffline || location.pathname === "/my-downloads" ? (
            <div
              role={"button"}
              className="controller d-flex align-items-center"
              onClick={(e) => watchNow(e, isPaid ? true : isFree)}
            >
              <img src={playButton} alt={t("watchNow")} />
              <span>{t("watchNow")}</span>
            </div>
          ) : (
            ""
          )}
          {location.pathname !== "/my-downloads" ? (
            !isOffline ? (
              <div
                role={"button"}
                className="controller "
                onClick={(e) => addToWishList(e)}
              >
                {isWishList ? (
                  <img src={checkedWishListButton} alt={t("myList")} />
                ) : (
                  <img src={addWishlistButton} alt={t("myList")} />
                )}
                {t("myList")}
              </div>
            ) : (
              ""
            )
          ) : (
            <div
              className="video-list-item__download-icon d-flex align-items-center gap-1"
              onClick={isDownloaded ? handleDeleteVideo : downloadVideoHandler}
            >
              <img
                src={isDownloaded ? binIcon : downloadIcon}
                alt="download"
                style={{ width: "16px", height: "16px" }}
              />
              <span>{isDownloaded ? "Downloaded" : "Download"} </span>
            </div>
          )}

          {/* {!isOffline && location.pathname === "/my-downloads" && (
            <div
              role={"button"}
              className='controller '
              onClick={isDownloaded ? handleDeleteVideo : downloadVideoHandler}
            >
              <img
                onMouseEnter={onDownloadMouseEnter}
                onMouseLeave={onDownloadMouseLeave}
                src={
                  isDownloadHov
                    ? isDownloaded
                      ? downloadedHover
                      : downloadHover
                    : isDownloaded
                    ? downloadedRegular
                    : downloadRegular
                }
                alt='download'
              />
              {isDownloaded ? `${t("downloaded")}` : `${t("download")}`}
            </div>
          )
          } */}
        </div>
        <SubscribenowModal
          open={isSubscribeNowModalVisible}
          onCancel={() => {
            //temp workaround for closing the modal after opening it with the backgroundimge while the user unsubscribed
            setTimeout(() => {
              document.body.style.overflow = "";
              updateIsSubscribeNowModalVisible(false);
            }, 1);
          }}
        />
        <Modal
          centered
          open={showDownloadingModal}
          width={800}
          onCancel={closeDownloadModalHanlder}
          className="download-modal modal-container"
          footer={false}
          closeIcon={
            <img src={closeIcon} alt="icon" style={{ width: "30px" }} />
          }
          destroyOnClose
        >
          {isDownloading && (
            <div className="text-center">
              <h5 className="text-center fw-700 f-25 title my-4">
                {t("downloading")}
              </h5>

              <div className="my-4 py-4">
                {/* <Lottie
                isClickToPauseDisabled={true}
                options={defaultOptions}
                height={120}
                width={120}
              /> */}
                <DownloadProgress percentage={percentage} fileInfo={fileInfo} />
              </div>
            </div>
          )}
        </Modal>
      </div>
    </BackgroundImage>
  );
};

export default VideoListItem;
