import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Row, Col, Button } from "antd";
import Skeleton from "react-loading-skeleton";
import { getWishlist } from "./network";
import VideoListItem from "modules/Shared/VideoListItem";
import WebsiteTitle from "helpers/WebsiteTitle";
import { LANG_PARAMS } from "localization/translation-params";
import Lottie from "react-lottie";
import noVideos from "../../assets/icons/noVideosInList.json";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";

const MyList = () => {
  const [myList, setMyList] = useState([]);
  const [myListLoading, setMyListLoading] = useState(true);
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const getAllWishListVideos = () => {
    const payload = {
      lang,
      accountId: authorization?.online?.id,
    };
    getWishlist(
      payload,
      (success) => {
        setMyList(success.data);
        setMyListLoading(false);
      },
      (fail) => {
        setMyListLoading(false);
      }
    );
  };
  useEffect(() => {
    getAllWishListVideos();
  }, [lang, authorization?.online?.id]);
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noVideos,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleBackToHome = () => {
    navigate(ROUTES.HOME);
  };
  return (
    <>
      <WebsiteTitle title={t(LANG_PARAMS.wishList)} />
      <section className="main-section myList" style={{ marginBottom: "2rem" }}>
        <Container className="d-flex flex-1 flex-column">
          <div className="myList__title">
            <span className="text-white">{t(LANG_PARAMS.wishList)}</span>
          </div>
          <div className="skeleton-container">
            {myListLoading ? (
              new Array(4).fill(1).map((item, _) => (
                <Col md={6} xs={24} key={_}>
                  <Skeleton width={"100%"} height={232} borderRadius={25} />
                </Col>
              ))
            ) : !myList.length ? (
              <div className="text-center m-auto">
                <Lottie
                  isClickToPauseDisabled={true}
                  options={defaultOptions}
                  height={180}
                  width={180}
                />
                <div className="fw-600 f-24 noDownloads-title text-red">
                  {t("noVideosInList")}
                </div>
                <div className="noDownloads-Info mb-4 text-white">
                  {t("goHomeToAddInList")}
                </div>
                <Button className="button-primary" onClick={handleBackToHome}>
                  {t("backToHome")}
                </Button>
              </div>
            ) : (
              <div className="myList-container">
                {myList.map((item, _) => {
                  const details = {
                    header: {
                      imgPath: item.imgPath,
                      title: item.title,
                      _id: item.videoHeaderId,
                      videoId: item.videoId,
                      isSeries: item.isSeries,
                      isWished: true,
                      genre: item?.genre,
                      category: item?.category,
                      hasFreeVideo: item?.hasFreeVideo,
                      firstEpisodeId: item?.firstEpisodeId,
                    },
                  };
                  return (
                    <VideoListItem
                      details={details}
                      getAllWishListVideos={getAllWishListVideos}
                      key={item.videoId}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default MyList;
