import ROUTES from "constants/_routes";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Input, Dropdown as AntDropdown } from "antd";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "services";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import { LANG_PARAMS } from "localization/translation-params";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import GifPlayer from "react-gif-player";
import giftIcon from "assets/images/giftIcon.gif";

import localizationAr from "assets/icons/ar.svg";
import localizationEn from "assets/icons/localization-en.svg";
import amuseLogo from "assets/images/amuse-logo.svg";

import search from "assets/icons/search.svg";
import searchWhite from "assets/icons/search-white.svg";

const MainHeaderLgScreen = forwardRef(
  (
    {
      searchBarStatus,
      toggleSearchBarStatus,
      searchResults,
      onSearchMovieClick,
      searchValue,
      setSearchValue,
      // getPopupContainer,
      whiteNavbar,
      avatarLoading,
      avatarImg,
      toggleLogoutModalVisible,
      handleSubscribeBtn,
      openGiftModalHandler,
      changeLangAction,
    },
    ref
  ) => {
    const [reset, updateReset] = useState(false);
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { authorization } = useSelector((state) => state.auth);
    const { lang } = useSelector((state) => state.lang);

    const isPaid = authorization?.isPaid?.isPaid;
    const hasPaidBefore = authorization?.isPaid?.hasPaidBefore;

    useEffect(() => {
      if (searchBarStatus) {
        inputRef.current.focus();
      }
    }, [searchBarStatus]);

    return (
      <Container fluid className="custom-container main-header__lg">
        <Navbar.Brand
          onClick={() => {
            updateReset((prevValue) => !prevValue);
          }}
          className={`${searchBarStatus ? "main-header__lg--view" : undefined}`}
        >
          <Link to={{ pathname: ROUTES.HOME }} state={{ reset }}>
            <img src={amuseLogo} alt="amuse the kids" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="amuse-nav__menus me-auto">
            <ul className="amuse-nav__ul-center">
              <li className="item">
                <Link
                  to={ROUTES.HOME}
                  className={`item ${
                    searchBarStatus ? "main-header__lg--view" : undefined
                  }`}
                >
                  {t("home")}
                </Link>
              </li>
              {authorization?.online ? (
                <li
                  className={`item ${
                    searchBarStatus ? "main-header__lg--view" : undefined
                  }`}
                >
                  <Link
                    to={ROUTES.MY_LIST}
                    onClick={(e) => {
                      if (!navigator.onLine) {
                        e.preventDefault();
                        alert(
                          t(
                            "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                          )
                        );
                        return;
                      }
                    }}
                  >
                    {t("wishlist")}
                  </Link>
                </li>
              ) : (
                <li
                  className={`item ${
                    searchBarStatus ? "main-header__lg--view" : undefined
                  }`}
                >
                  <Link to={ROUTES.SIGNIN}>{t("signin")}</Link>
                </li>
              )}
            </ul>
            <ul
              className={`amuse-nav__ul-end ${
                searchBarStatus ? "d-flex flex-1" : ""
              }`}
              ref={ref}
            >
              <li className={"item d-flex flex-1 flex-column"}>
                <AntDropdown
                  menu={{
                    items: searchResults,
                    onClick: onSearchMovieClick,
                    // onClick: noResult ? null : onSearchMovieClick,
                  }}
                  overlayClassName="navbar-search-dropdown py-2"
                  open={searchValue?.length > 0 && searchBarStatus}
                  getPopupContainer={() => ref.current}
                >
                  <div
                    className={`${
                      searchBarStatus
                        ? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
                        : ""
                    }`}
                  >
                    <Input
                      ref={inputRef}
                      value={searchValue}
                      type="search"
                      className={`${searchBarStatus ? "d-block" : "d-none"}`}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                    <button
                      onClick={(e) => {
                        if (!navigator.onLine) {
                          return alert(
                            t(
                              "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                            )
                          );
                        }
                        toggleSearchBarStatus(true);
                      }}
                      style={{ background: "none", border: "none", padding: 0 }}
                    >
                      <img
                        src={
                          whiteNavbar || searchBarStatus ? search : searchWhite
                        }
                        alt="search in amuse"
                        className="search-icon"
                      />
                    </button>
                  </div>
                </AntDropdown>
              </li>

              {authorization?.online?.id && (
                <li className="item">
                  <Dropdown
                    className={`amuse-nav__menu ${
                      whiteNavbar ? "white-nav" : ""
                    }`}
                  >
                    <Dropdown.Toggle
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="item">
                        {avatarLoading ? (
                          <SkeletonAvatar active size="large" /> // Placeholder for the avatar
                        ) : (
                          <img
                            className="amuse-nav__avatar"
                            src={addTokenToMedia(baseUrl + avatarImg)}
                            alt="avatar"
                          />
                        )}
                      </div>
                      {/* {t(LANG_PARAMS.manageAccount)} */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={`
                                 ${
                                   lang === "ar" ? "dropdown-menu-item-ar" : ""
                                 } 
                                `}
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                              )
                            );
                            return;
                          } else {
                            navigate(ROUTES.WATCHERS);
                          }
                        }}
                      >
                        {t(LANG_PARAMS.switchProfile)}
                      </Dropdown.Item>

                      {authorization?.online?.isParent && (
                        <Dropdown.Item
                          className={`
                                 ${
                                   lang === "ar" ? "dropdown-menu-item-ar" : ""
                                 } 
                                `}
                          onClick={(e) => {
                            if (!navigator.onLine) {
                              e.preventDefault();
                              alert(
                                t(
                                  "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                                )
                              );
                              return;
                            } else {
                              navigate(ROUTES.ACCOUNT_PROFILE);
                            }
                          }}
                        >
                          {t("profile")}
                        </Dropdown.Item>
                      )}

                      {/* DOWNLOADS: */}
                      {/* <Dropdown.Item
                                className={`${
                                  lang === "ar" ? "dropdown-menu-item-ar" : ""
                                }`}
                                onClick={() => navigate(ROUTES.MY_DOWNLOADS)}
                              >
                                {t("myDownloads")}
                              </Dropdown.Item> */}

                      <Dropdown.Item
                        className={`
                                 ${
                                   lang === "ar" ? "dropdown-menu-item-ar" : ""
                                 } 
                                `}
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                              )
                            );
                            return;
                          } else {
                            document.body.style.overflow = "hidden";
                            toggleLogoutModalVisible(true);
                          }
                        }}
                      >
                        {t("logout")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
              <ul className="amuse-nav-btnicongift-container">
                <li className="item item-btn">
                  {!(hasPaidBefore && isPaid) && (
                    <Button
                      className="button-primary amuse-nav__btn"
                      onClick={handleSubscribeBtn}
                    >
                      {
                        hasPaidBefore
                          ? t("renewSubscription") // hasPaidBefore is true and isPaid is false
                          : t("subscribeNowWithout") // hasPaidBefore is false and isPaid is either true or false
                      }
                    </Button>
                  )}
                </li>
                {navigator.onLine && (
                  <li className="item item-gift">
                    <div onClick={openGiftModalHandler}>
                      <GifPlayer gif={giftIcon} autoplay />
                    </div>
                  </li>
                )}
                <li className="item-lang">
                  {lang === "en" ? (
                    <img
                      className={`${lang === "en"}`}
                      src={localizationAr}
                      alt="localization-ar"
                      role={"button"}
                      onClick={changeLangAction}
                    />
                  ) : (
                    <img
                      src={localizationEn}
                      alt="localization-en"
                      role={"button"}
                      onClick={changeLangAction}
                    />
                  )}
                </li>
              </ul>
            </ul>
          </div>
        </Navbar.Collapse>
      </Container>
    );
  }
);

export default MainHeaderLgScreen;
