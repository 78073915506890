import { createSearchParams } from "react-router-dom";

export const PATHS_PARAMS = {
  getBanners: "getBanners",
  getGenres: "getGenres",
  getCategoriesByGenreId: "getCategoriesByGenreId",
  getVideosOfCategory: "getVideosOfCategory",
  getBannerDetails: "getBannerDetails",
  getMovieParts: "getMovieParts",
  getSeriesSessons: "getSeriesSessons",
  getBannerById: "getBannerById",
  getAllVideosByCategoryId: "getAllVideosByCategoryId",
  getVideoDetails: "getVideoDetails",
  getWishList: "getWishList",
  addToWishList: "addToWishList",
  contactUs: "contactUs",
  getUserAccounts: "getUserAccounts",
  enterUserAccount: "enterUserAccount",
  deleteUserAccount: "deleteUserAccount",
  genderAvatars: "genderAvatars",
  getRelatedAvatars: "getRelatedAvatars",
  checkPayment: "checkPayment",
  getGiftQues: "getGiftQues",
  postQuestAnswer: "postQuestAnswer",
  getPackages: "getPackages",
  getPaymentPreferences: "getPaymentPreferences",
  fawryPay: "fawryPay",
  getMyPackages: "getMyPackages",
  continueWatching: "continueWatching",
  getTermsAndConditions: "getTermsAndConditions",
  getPrivacyPolicy: "getPrivacyPolicy",
  addVideoViewNumber: "addVideoViewNumber",
  addUserDownloads: "addUserDownloads",
  getDownloadedVideos: "getDownloadedVideos",
  getSavedCard: "getSavedCard",
  deleteSavedCard: "deleteSavedCard",
  placeorder: "placeorder",
  setDefaultCard: "setDefaultCard",
  getUserFreeDays: "getUserFreeDays",
  getCastsData: "getCastsData",
  getAudiosAndSubtitle: "getAudiosAndSubtitle",
  enterCouponCode: "enterCouponCode",
  getQueryParamsForTpayScriptUrl: "getQueryParamsForTpayScriptUrl",
  verifySubscriptionTpay: "verifySubscriptionTpay",
  subscriptionCallback: "subscriptionCallback",
  hasSubscription: "hasSubscription",
  resendSubscriptionContractVerificationSMS:
    "resendSubscriptionContractVerificationSMS",
  sendSmsToAlreadySubscribedUser: "sendSmsToAlreadySubscribedUser",
  checkVideoIsFree: "checkVideoIsFree",
  operatorDailyCost: "operatorDailyCost",
  sendVideoViews: "sendVideoViews",
};

export const PATHS = (targetApi, lang = null, params) => {
  let paths = {
    getGenres: "api/genres",
    getBanners: "api/getbanner",
    getCategoriesByGenreId: "api/video/categoriesByGenre",
    getVideosOfCategory: "api/video/category",
    getBannerDetails: "api/video/videoDetails",
    getMovieParts: "api/video/relatedParts",
    getSeriesSessons: "api/video/relatedSeries",
    getBannerById: "api/getSingleBanner",
    getAllVideosByCategoryId: "api/video/category",
    getVideoDetails: "api/video/videoDetails",
    getWishList: "api/user_wish_list",
    addToWishList: "api/user_wish_list",
    contactUs: "api/contactUs",
    getUserAccounts: "api/userAccounts",
    enterUserAccount: "api/userAccounts",
    deleteUserAccount: "api/userAccount/delete",
    genderAvatars: "api/user/avatars",
    getRelatedAvatars: "api/user/relatedAvatars",
    checkPayment: "api/checkPayment",
    getGiftQues: "api/question",
    postQuestAnswer: "api/question/answerquestion",
    getPackages: "api/packages",
    getPaymentPreferences: "api/clientProjects/paymentPrefernces",
    fawryPay: "api/fawry/charge",
    getMyPackages: "api/myPackages",
    continueWatching: "api/ContinueWatching",
    getTermsAndConditions: "api/termsAndConditions",
    getPrivacyPolicy: "api/privacyPolicy",
    addVideoViewNumber: "api/videoViews",
    addUserDownloads: "api/userDownloads",
    getDownloadedVideos: "api/userDownloads",
    getSavedCard: "api/paymob/getSavedCard",
    deleteSavedCard: "api/paymob/deleteSavedCard",
    placeorder: "api/paymob/PlaceOrder",
    setDefaultCard: "api/paymob/setDefaultCard",
    getUserFreeDays: "api/user/getFreeDays",
    getCastsData: "api/video/getCasts",
    getAudiosAndSubtitle: "api/video/audiosAndSubtitles",
    enterCouponCode: "api/codes/enterCodeByUser",
    getQueryParamsForTpayScriptUrl: "api/tpay/getQueryParamsForTpayScriptUrl",
    verifySubscriptionTpay: "api/tpay/verifySubscriptionContract",
    subscriptionCallback: "api/tpay/tpaySubscribeCallback",
    hasSubscription: "api/tpay/checkUserHasTpaySubcription",
    resendSubscriptionContractVerificationSMS:
      "api/tpay/resendSubscriptionContractVerificationSMS",
    sendSmsToAlreadySubscribedUser: "api/tpay/sendSms",
    checkVideoIsFree: "api/video/checkVideoIsFree",
    operatorDailyCost: "api/tpay/getOperatorsCodesForTpay",
    sendVideoViews: "api/videoViews",
  };

  let qParams = {
    ...(lang && { lang }),
    ...(params && { params }),
  };

  let returnedPath = paths[targetApi];
  if (qParams.params || qParams.lang) {
    returnedPath += "?" + createSearchParams(qParams.params || qParams.lang);
  }
  return returnedPath;
};
