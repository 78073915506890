import { useState, useEffect, useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Navbar } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLang } from "store/lang";

import NoResultsIcon from "assets/icons/NoResultsIcon.svg";
import ROUTES, { pagesNotHaveNavbar } from "constants/_routes";
import { baseUrl } from "services";
import { changeLanguage, getSearchResults } from "./network";
import SignInModal from "./SignInModal";
import Skeleton from "react-loading-skeleton";

import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { onLangChange, toastError } from "helpers/toasters";
import LogoutModal from "./LogoutModal";
import JoinToCompetitionModal from "./JoinToCompetitionModal";

import { getUserData } from "modules/Authentications/network";
import { changeAuthData } from "store/authorization";
import SubscribenowModal from "modules/WatchNow/components/SubscribenowModal";
import MainHeaderLgScreen from "./MainnHeaderLgScreen";
// import MainHeaderTabletScreen from "./MainHeaderTabletScreen";
import MainHeaderMobileScreen from "./MainHeaderMobileScreen";

function MainHeader() {
  const location = useLocation();
  const { lang } = useSelector((state) => state.lang);
  const { t, i18n } = useTranslation();
  const { authorization } = useSelector((state) => state.auth);
  const [avatarImg, setavatarSrc] = useState(null);
  const [avatarLoading, setavatarLoading] = useState(false);
  const avatarDetailsAuth = authorization?.userAccounts
    ? authorization?.userAccounts.find(
        (item) => item?.id === authorization?.online?.["id"]
      )
    : null;

  const isAuth = authorization?.token;
  const isPaid = authorization?.isPaid?.isPaid;

  const [searchBarStatus, toggleSearchBarStatus] = useState(false);
  // const [searchBarStatusTab, toggleSearchBarStatusTab] = useState(false);
  const [searchBarStatusMob, toggleSearchBarStatusMob] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchResults, updateSearchResults] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const [isModalVisible, toggleModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [notInHome, setNotInHome] = useState(location.pathname === "/");
  const [whiteNavbar, setWhiteNavbar] = useState(!location.pathname === "/");
  const [isLogoutModalVisible, toggleLogoutModalVisible] = useState(false);
  const [joinToCompetitionModalVisible, setJoinToCompetitionModalVisible] =
    useState(false);
  const [isSubscribeNowModalVisible, updateIsSubscribeNowModalVisible] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navbar = useRef(null);

  const wrapperRef = useRef(null);
  // const wrapperRefTab = useRef(null);
  const wrapperRefMob = useRef(null);

  const collapseBtn = useRef(null);
  const collapseArea = useRef(null);
  const collapseMobileNavbarRef = useRef(null);

  useOnClickOutside(collapseMobileNavbarRef, handleCollapseMobileNavbar);
  useOnClickOutside(wrapperRef, () =>
    handleCollapseSearchBar(toggleSearchBarStatus)
  );
  // useOnClickOutside(wrapperRefTab, () =>
  //   handleCollapseSearchBar(toggleSearchBarStatusTab)
  // );
  useOnClickOutside(wrapperRefMob, () =>
    handleCollapseSearchBar(toggleSearchBarStatusMob)
  );

  useEffect(() => {
    if (!navigator.onLine) {
      setavatarSrc(authorization?.online?.avatar);
    }
    if (authorization?._id && navigator.onLine) {
      setavatarLoading(true);
      getUserData(
        { userId: authorization._id },
        (res) => {
          dispatch(changeAuthData({ userAccounts: res.data.userAccounts }));
          const avatarDetails = res.data.userAccounts
            ? res.data.userAccounts.find(
                (item) => item?.id === authorization?.online?.["id"]
              )
            : null;
          setavatarSrc(avatarDetails.avatar);
          setavatarLoading(false);
        },
        (fail) => {
          if (fail?.data) toastError(fail?.data?.err_msg);
        }
      );
    }
  }, [
    avatarDetailsAuth?.avatar,
    authorization?.userAccounts?.length,
    authorization._id,
    authorization?.online?.["id"],
  ]);

  useEffect(() => {
    if (location.pathname === "/") {
      setNotInHome(false);
    } else setNotInHome(true);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") {
      document.getElementById("root").style.marginBlock = 0 + "px";
      window.addEventListener("scroll", onScrollHome);
    } else {
      document.getElementById("root").style.marginTop =
        pagesNotHaveNavbar.includes(location.pathname)
          ? 0 + "px"
          : navbar?.current?.clientHeight + 5 + "px";
      window.removeEventListener("scroll", onScrollHome);
      setWhiteNavbar(true);
    }
    return () => {
      window.removeEventListener("scroll", onScrollHome);
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname !== ROUTES.HOME &&
      location.pathname !== ROUTES.BANNER
    ) {
      setWhiteNavbar(true);
    } else {
      setWhiteNavbar(false);
    }
  }, [location]);

  useEffect(() => {
    let handler;
    if (searchValue.length) {
      handler = setTimeout(() => {
        onSearchValueChange();
      }, 500);
    }
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    onLangChange(lang === "ar");
  }, [lang]);

  function handleToggle() {
    setExpanded(!expanded);
  }

  function handleCollapseMobileNavbar() {
    collapseBtn.current.className = "navbar-toggler collapsed";
    collapseArea.current.className = "navbar-collapse collapse";
  }

  function handleCollapseSearchBar(toggleFn) {
    setSearchValue("");
    setTimeout(() => {
      toggleFn(false);
    }, 500);
  }

  function openGiftModalHandler(e) {
    if (!navigator.onLine) {
      e.preventDefault();
      alert(t("YouareCurrentlyOfflinePleaseCheckYourInternetConnection"));
      return;
    }
    document.body.style.overflow = "hidden";
    setJoinToCompetitionModalVisible(true);
  }

  function onSearchMovieClick({ key }) {
    if (!navigator.onLine) return;
    if (isAuth) {
      const selectedVideoDetails = searchResults.filter(
        (result) => result?.key === key
      );

      if (selectedVideoDetails?.length > 0) {
        let data =
          selectedVideoDetails[0].video.video[0].videoHeaderId +
          "-" +
          selectedVideoDetails[0].video.video[0]._id +
          "-" +
          (selectedVideoDetails[0].video.video[0].isSeries ? 1 : 0);

        const watchNowDetails = {
          qp: data,
        };

        if (isPaid || selectedVideoDetails[0]?.video?.hasFreeVideos) {
          navigate({
            pathname: ROUTES.WATCH_NOW,
            search: `?${createSearchParams(watchNowDetails)}`,
          });
          setSearchValue("");
        } else {
          document.body.style.overflow = "hidden";
          setSearchValue("");
        }
      }
    } else {
      toggleModalVisible(true);
      setSearchValue("");
    }
  }

  function Box({ children }) {
    return (
      <div className="d-flex align-items-center" style={{ height: "100px" }}>
        {children}
      </div>
    );
  }

  function onSearchValueChange() {
    let data = {
      lang,
      search: searchValue,
      userAccountId: "",
    };
    const loadingNode = [
      {
        key: 1,
        label: (
          <div className="text-center no-results-found">
            <Skeleton count={3} wrapper={Box} height={100} />
          </div>
        ),
      },
    ];
    updateSearchResults(loadingNode);
    getSearchResults(
      data,
      (success) => {
        let AllVideos = success.data;
        const newVideosArr = [];
        AllVideos.forEach((video) => {
          newVideosArr.push({
            key: video?._id,
            video,
            label: (
              <div className="d-flex align-items-center">
                <img
                  src={baseUrl + video?.imgPath}
                  alt="movie-img"
                  className="movie-cover"
                />
                <div className="title mx-2">{video?.title}</div>
              </div>
            ),
          });
        });
        if (newVideosArr?.length) {
          setNoResult(false);
          updateSearchResults(newVideosArr);
        } else {
          setNoResult(true);
          const noResultsNode = [
            {
              key: 1,
              label: (
                <div className="text-center no-results-found">
                  <div className="title m-2">{t("noDataFound")}</div>
                  <img src={NoResultsIcon} alt="no-result" className="my-2" />
                </div>
              ),
              disabled: true,
            },
          ];
          updateSearchResults(noResultsNode);
        }
      },
      (fail) => {}
    );
  }

  function changeLangAction() {
    if (authorization?.online?.id) {
      // Related to change language associated with prefered language
      // dispatch(changeLangByUser(true));

      localStorage.setItem(
        `${authorization?.online?.id}`,
        lang === "en" ? "ar" : "en"
      );

      let data = {
        accountId: authorization?.online?.id,
        lang: lang === "en" ? "ar" : "en",
        accountAppLanguage: lang === "en" ? "ar" : "en",
      };
      if (navigator.onLine) {
        changeLanguage(
          data,
          (success) => {},
          (fail) => {}
        );
      }
    }
    const newLang = lang === "en" ? "ar" : "en";
    // Update HTML element attributes
    document.documentElement.lang = newLang;
    document.documentElement.dir = newLang === "ar" ? "rtl" : "ltr";

    // Update language in i18n and Redux store
    i18n.changeLanguage(newLang);
    dispatch(changeLang(newLang));
    // getCurrentLanguageToService(lang);
    localStorage.setItem("lang", newLang);
  }

  function onScrollHome() {
    if (location.pathname === "/") {
      if (window.scrollY >= navbar?.current?.clientHeight) {
        setWhiteNavbar(true);
      } else {
        setWhiteNavbar(false);
      }
    } else {
      setWhiteNavbar(false);
    }
  }

  // function getPopupContainer() {
  //   return wrapperRef.current;
  // }

  // function getPopupContainerTab() {
  //   return wrapperRefTab.current;
  // }

  // function getPopupContainerMob() {
  //   return wrapperRefMob.current;
  // }

  function goTo(path) {
    handleCollapseMobileNavbar();
    navigate(path);
  }

  function handleSubscribeBtnLg(e) {
    if (!navigator.onLine) {
      e.preventDefault();
      alert(t("YouareCurrentlyOfflinePleaseCheckYourInternetConnection"));
      return;
    }

    if (!isAuth) {
      navigate(ROUTES.SIGNIN);
    }

    if (isAuth && authorization?.online?.isParent) {
      navigate(ROUTES.SUBSCRIPTION);
    }

    if (isAuth && !authorization?.online?.isParent) {
      document.body.style.overflow = "hidden";
      updateIsSubscribeNowModalVisible(true);
    }
  }

  function handleSubscribeBtnSm(e) {
    if (!navigator.onLine) {
      e.preventDefault();
      alert(t("YouareCurrentlyOfflinePleaseCheckYourInternetConnection"));
      return;
    }
    if (!isAuth) {
      navigate(ROUTES.SIGNIN);
    }

    if (isAuth && authorization?.online?.isParent) {
      handleToggle();
      handleCollapseMobileNavbar();
      navigate(ROUTES.SUBSCRIPTION);
    }

    if (isAuth && !authorization?.online?.isParent) {
      document.body.style.overflow = "hidden";
      updateIsSubscribeNowModalVisible(true);
    }
  }

  if (pagesNotHaveNavbar.includes(location.pathname)) return <></>;

  return (
    <Navbar
      ref={navbar}
      collapseOnSelect
      expand="md"
      expanded={expanded}
      onToggle={handleToggle}
      className={`amuse-nav  ${
        whiteNavbar ? "amuse-nav--white" : "navbar-dark"
      } ${notInHome ? "amuse-nav--white" : ""}`}
    >
      <MainHeaderLgScreen
        ref={wrapperRef}
        searchBarStatus={searchBarStatus}
        toggleSearchBarStatus={toggleSearchBarStatus}
        searchResults={searchResults}
        onSearchMovieClick={onSearchMovieClick}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        // getPopupContainer={getPopupContainer}
        whiteNavbar={whiteNavbar}
        avatarLoading={avatarLoading}
        avatarImg={avatarImg}
        toggleLogoutModalVisible={toggleLogoutModalVisible}
        openGiftModalHandler={openGiftModalHandler}
        changeLangAction={changeLangAction}
        handleSubscribeBtn={handleSubscribeBtnLg}
      />

      {/* <MainHeaderTabletScreen
        ref={wrapperRefTab}
        searchBarStatus={searchBarStatusTab}
        toggleSearchBarStatus={toggleSearchBarStatusTab}
        searchResults={searchResults}
        onSearchMovieClick={onSearchMovieClick}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        // getPopupContainer={getPopupContainerTab}
        whiteNavbar={whiteNavbar}
        avatarImg={avatarImg}
        toggleLogoutModalVisible={toggleLogoutModalVisible}
        openGiftModalHandler={openGiftModalHandler}
        changeLangAction={changeLangAction}
        handleSubscribeBtn={handleSubscribeBtnSm}
      /> */}

      <MainHeaderMobileScreen
        ref={{
          wrapperRefMob,
          collapseBtn,
          collapseArea,
          collapseMobileNavbarRef,
        }}
        searchBarStatus={searchBarStatusMob}
        toggleSearchBarStatus={toggleSearchBarStatusMob}
        searchResults={searchResults}
        onSearchMovieClick={onSearchMovieClick}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        // getPopupContainer={getPopupContainerMob}
        whiteNavbar={whiteNavbar}
        avatarImg={avatarImg}
        toggleLogoutModalVisible={toggleLogoutModalVisible}
        openGiftModalHandler={openGiftModalHandler}
        changeLangAction={changeLangAction}
        handleToggle={handleToggle}
        handleCollapseMobileNavbar={handleCollapseMobileNavbar}
        goTo={goTo}
        handleSubscribeBtn={handleSubscribeBtnSm}
      />

      <SignInModal
        isModalVisible={isModalVisible}
        onCancel={() => {
          toggleModalVisible(false);
        }}
      />

      <SubscribenowModal
        open={isSubscribeNowModalVisible}
        onCancel={() => {
          document.body.style.overflow = "";
        }}
      />

      <LogoutModal
        isModalVisible={isLogoutModalVisible}
        onCancel={() => {
          document.body.style.overflow = "";
          toggleLogoutModalVisible(false);
        }}
      />
      <JoinToCompetitionModal
        isModalVisible={joinToCompetitionModalVisible}
        onCancel={() => {
          document.body.style.overflow = "";
          setJoinToCompetitionModalVisible(false);
        }}
      />
    </Navbar>
  );
}

export default MainHeader;
