import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LANG_PARAMS } from "localization/translation-params";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";
import Skeleton from "react-loading-skeleton";
import { Image } from "react-image-and-background-image-fade";
import { getGeners } from "../network";
// icons
import arabicSoonImage from "assets/images/soonArabic.svg";
import englishSoonImage from "assets/images/soonEnglish.svg";
// import TvIcon from "assets/images/tv.svg";
// import MoviesIcon from "assets/images/movies.svg";
// import AnimesIcon from "assets/images/anime.svg";
// import TvProgramIcon from "assets/images/programs.svg";
// import FunStoreIcon from "assets/images/fun-store.svg";
import TvIcon from "assets/images/tv-new.png";
import MoviesIcon from "assets/images/movies-new.png";
import AnimesIcon from "assets/images/anime-new.png";
import TvProgramIcon from "assets/images/programs-new.png";
import FunStoreIcon from "assets/images/fun-store-new.png";
import { baseUrl } from "services";

import Animes from "assets/icons/Anime.svg";
import Series from "assets/icons/Series.svg";
import Movies from "assets/icons/Movies.svg";
import Know from "assets/icons/Know.svg";
import Stores from "assets/icons/Store.svg";

function Generies({ setSelectedGenreId }) {
  const [generies, setGeneries] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const arrToLoad = new Array(5).fill(0);
  const { lang } = useSelector((state) => state.lang);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    getGeners({ lang }, (success) => {
      const { data } = success;
      setGeneries(data);
    });
  }, [lang, setSelectedGenreId]);

  const allGenries = [
    {
      id: generies[0]?.id,
      name: generies[0]?.name,
      englishName: "series",
      // imgPath: TvIcon,
      // imgPath: `${baseUrl}${generies[0]?.imgPath}`,
      imgPath: Series,
      // isSoon: generies[0]?.isSoon,
      index: generies[0]?.index,
      backgroundColor: generies[0]?.backgroundColor,
    },
    {
      id: generies[1]?.id,
      name: generies[1]?.name,
      englishName: "movies",
      imgPath: `${baseUrl}${generies[1]?.imgPath}`,
      // isSoon: generies[1]?.isSoon,
      index: generies[1]?.index,
      backgroundColor: generies[1]?.backgroundColor,
    },
    {
      id: generies[2]?.id,
      name: generies[2]?.name,
      englishName: "anime",
      imgPath: `${baseUrl}${generies[2]?.imgPath}`,
      // isSoon: generies[2]?.isSoon,
      index: generies[2]?.index,
      backgroundColor: generies[2]?.backgroundColor,
    },
    {
      id: generies[3]?.id,
      name: generies[3]?.name,
      englishName: "know",
      imgPath: `${baseUrl}${generies[3]?.imgPath}`,
      // isSoon: generies[3]?.isSoon,
      index: generies[3]?.index,
      backgroundColor: generies[3]?.backgroundColor,
    },
    {
      id: generies[4]?.id,
      name: generies[4]?.name,
      imgPath: `${baseUrl}${generies[4]?.imgPath}`,
      englishName: "store",
      // isSoon: generies[4]?.isSoon,
      index: generies[4]?.index,
      backgroundColor: generies[4]?.backgroundColor,
    },
  ];

  function selectGenre(genreId) {
    setSelectedGenreId(genreId);
    setSelectedGenre(genreId);
  }

  const handleClearFilter = () => {
    selectGenre(null);
  };

  useEffect(() => {
    handleClearFilter();
  }, [location?.state?.reset]);

  return (
    <section className="generies">
      <Container className="justify-content-between">
        {/* <div className="d-flex justify-content-between align-items-center">
          <div className="generies__title">{t(LANG_PARAMS.filter)}</div>
          <div
            className="text-underline fw-600 cursor-pointer"
            onClick={handleClearFilter}
          >
            {t("clearFilter")}
          </div>
        </div> */}
        {generies.length ? (
          <div className="generies__grid-container">
            {allGenries.map((genre, genreIndex) => (
              <div className="generies__grid-item" key={genreIndex}>
                <figure className="generies__fig">
                  {/* {lang === "ar" && genre.isSoon ? (
                    <img
                      src={arabicSoonImage}
                      className='generies__fig-soon'
                      alt='قريباً'
                    />
                  ) : null}
                  {lang === "en" && genre.isSoon ? (
                    <img
                      src={englishSoonImage}
                      className='generies__fig-soon'
                      alt='soon'
                    />
                  ) : null} */}

                  <Image
                    wrapperClassName={`image-wrapper image-wrapper--${genre?.englishName}`}
                    src={genre?.imgPath}
                    // src={Anime}
                    // wrapperClassName="image-wrapper"
                    // width='200px'
                    // height='150px'
                    alt="navigator"
                    role="button"
                    onClick={() => selectGenre(genre?.id)}
                  />
                  <figcaption
                    className={selectedGenre === genre?.id ? "active" : null}
                  >
                    {genre?.name}
                  </figcaption>
                </figure>
              </div>
            ))}
            {/* <div className="generies__grid-item">
              <figure className="generies__fig">
                <span className="generies__fig-soon"></span>
                <Image
                  wrapperClassName={"image-wrapper"}
                  src={TvIcon}
                  alt="navigator"
                  role="button"
                  onClick={(e) => selectGenre("622f30a3825d6f969ea762c4")}
                />

                <figcaption
                  className={
                    selectedGenre === "622f30a3825d6f969ea762c4"
                      ? "active"
                      : null
                  }
                >
                  {t(LANG_PARAMS.tvshow)}
                </figcaption>
              </figure>
            </div>
            <div className="generies__grid-item">
              <figure className="generies__fig">
                <span className="generies__fig-soon">{t("soon")}</span>
                <Image
                  wrapperClassName={"image-wrapper"}
                  src={MoviesIcon}
                  alt="navigator"
                  role="button"
                  onClick={(e) => selectGenre("622f307f825d6f969ea76298")}
                />
                <figcaption
                  role={"button"}
                  className={
                    selectedGenre === "622f307f825d6f969ea76298"
                      ? "active"
                      : null
                  }
                >
                  {t(LANG_PARAMS.movies)}
                </figcaption>
              </figure>
            </div>
            <div className="generies__grid-item">
              <figure className="generies__fig">
                <span className="generies__fig-soon">{t("soon")}</span>
                <Image
                  wrapperClassName={"image-wrapper"}
                  src={AnimesIcon}
                  alt="navigator"
                  role="button"
                  onClick={(e) => selectGenre("62dffb043dc7a06bed808569")}
                />
                <figcaption
                  role={"button"}
                  className={
                    selectedGenre === "62dffb043dc7a06bed808569"
                      ? "active"
                      : null
                  }
                >
                  {t(LANG_PARAMS.anime)}
                </figcaption>
              </figure>
            </div>
            <div className="generies__grid-item">
              <figure className="generies__fig">
                <span className="generies__fig-soon">{t("soon")}</span>
                <Image
                  wrapperClassName={"image-wrapper"}
                  src={TvProgramIcon}
                  alt="navigator"
                  role="button"
                  onClick={(e) => selectGenre("630e26b7b5118eb80d1a27d4")}
                />
                <figcaption
                  role={"button"}
                  className={
                    selectedGenre === "630e26b7b5118eb80d1a27d4"
                      ? "active"
                      : null
                  }
                >
                  {t(LANG_PARAMS.tvProgram)}
                </figcaption>
              </figure>
            </div>
            <div className="generies__grid-item">
              <figure className="generies__fig">
                <span className="generies__fig-soon">{t("soon")}</span>
                <Image
                  wrapperClassName={"image-wrapper"}
                  src={FunStoreIcon}
                  alt="navigator"
                  role="button"
                  onClick={(e) => selectGenre("630e2658b5118eb80d1a27d2")}
                />
                <figcaption
                  role={"button"}
                  className={
                    selectedGenre === "630e2658b5118eb80d1a27d2"
                      ? "active"
                      : null
                  }
                >
                  {t(LANG_PARAMS.funStore)}
                </figcaption>
              </figure>
            </div> */}
          </div>
        ) : (
          <Row
            // gutter={[20, 20]}
            style={{
              margin: "auto",
              width: "60%",
              justifyContent: "space-between",
            }}
          >
            {arrToLoad.map((item, i) => (
              <Col xs={12} md={4} key={i} className="text-center">
                <Skeleton width={"83px"} height={"83px"} />
                <Skeleton
                  width={"100px"}
                  height={"1rem"}
                  style={{
                    marginTop: "2rem",
                  }}
                />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  );
}

export default Generies;
