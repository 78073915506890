import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AmuseLogo from "assets/images/amuse-logo.svg";
import { toastError } from "helpers/toasters";
import { baseUrl } from "services";
import { getGenderAvatar, getRelatedAvatar } from "./network";
import { Tooltip } from "antd";
import ROUTES from "constants/_routes";
import { onlineUser } from "store/authorization";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "modules/Shared/Spinner";
import { addTokenToMedia } from "helpers/addTokenToMedia";
const SelectAvatar = () => {
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [hoveredAvatar, setHoveredAvatar] = useState(null);
  const [skinsList, updateSkinsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const location = useLocation();

  const { isParent, isBoy, mode, from } = location.state;

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedAvatar) {
      setIsLoading(true);
      const payload = { name: selectedAvatar };

      getRelatedAvatar(
        payload,
        (success) => {
          updateSkinsList(success.data);
        },
        (fail) => {
          if (fail?.data?.err_msg) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        }
      );
    }
  }, [selectedAvatar]);

  useEffect(() => {
    setIsLoading(false);
  }, [skinsList]);

  useEffect(() => {
    const payload = {
      isParent,
      isBoy,
    };

    getGenderAvatar(
      payload,
      (success) => {
        setAvatars(success.data);
      },
      (fail) => {
        if (fail?.data?.err_msg) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  }, [isParent, isBoy]);

  const onAvatarToneSelect = (avatar) => {
    let avatarData = { _id: avatar.id, avatar: avatar.path };
    navigate(ROUTES.ADD_CHILDS, {
      state: {
        avatarId: avatar.id,
        avatarPath: baseUrl + avatar.path,
        avatarData,
        isParent,
        mode,
        from,
      },
    });
  };

  const skinsNode = () => {
    return (
      <>
        <span className={`${isLoading ? "d-block" : "d-none"}`}>
          <Spinner isNotFullPage={true} />
        </span>
        <div
          className={`text-center d-flex skins-node-container ${
            !isLoading ? "d-block" : "d-none"
          }`}
        >
          {skinsList.map((skin, index) => {
            return (
              <figure className="select-avatar__skin" key={index}>
                <img
                  src={addTokenToMedia(baseUrl + skin.path)}
                  alt="skin"
                  onClick={() => {
                    onAvatarToneSelect(skin);
                  }}
                  onMouseOver={() => setHoveredAvatar(skin)}
                  onMouseLeave={() => setHoveredAvatar(null)}
                  className="me-4 cursor-pointer"
                />
              </figure>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <section className="select-avatar main-section">
      {/* <div className="select-avatar__logo mb-4 text-center mt-5">
        <Link to="/">
          <img src={AmuseLogo} alt="amuse the kids" />
        </Link>
      </div> */}
      <div className="select-avatar__title text-center">
        {t("selectAvatar")}
      </div>
      <div className="select-avatar__allAvatars mb-4">
        <div className="d-flex justify-content-around flex-1 flex-wrap select-avatar__allAvatars__container ">
          {avatars.map((avatar, index) => {
            return (
              <div className="select-avatar__avatar text-center" key={index}>
                <Tooltip
                  title={skinsNode}
                  overlayClassName="skins-tooltip"
                  placement="bottom"

                  // autoAdjustOverflow={true} // Enable auto adjustment
                  // autoPlacement={true} // Enable auto placement
                >
                  <figure className="select-avatar__figure">
                    <img
                      className="select-avatar__image mb-3"
                      src={
                        selectedAvatar === avatar?.name
                          ? hoveredAvatar
                            ? addTokenToMedia(baseUrl + hoveredAvatar.path)
                            : addTokenToMedia(baseUrl + avatar.path)
                          : addTokenToMedia(baseUrl + avatar.path)
                      }
                      alt="Avatar"
                      role="button"
                      onClick={() => {
                        setSelectedAvatar(avatar.name);
                      }}
                      onMouseOver={() => {
                        setSelectedAvatar(avatar.name);
                      }}
                    />
                  </figure>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SelectAvatar;
