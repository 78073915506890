import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl, getCurrentLanguageToService } from "services";
import { useTranslation } from "react-i18next";
import ROUTES from "constants/_routes";
import { addChildFrom } from "../../store/addChild";
/*_______________ Component _______________ */
import Skeleton from "react-loading-skeleton";
import { Container } from "react-bootstrap";

/*_______________ Images _______________ */
import addAcount from "assets/images/addAccount.svg";
import AmuseLogo from "assets/images/amuse-logo.svg";
import camelAvatar from "assets/images/camel.svg";
import { Image } from "react-image-and-background-image-fade";

/*_______________ functions _______________ */
import DetectWindowSize from "helpers/DetectWindowSize";
import { getUserAccounts } from "modules/WatchNow/network";
import { toastError } from "helpers/toasters";
import { resetChildData } from "store/addChild";
import { changeAuthData, onlineUser } from "store/authorization";
import {
  getAllLanguages,
  logoutUserAccount,
} from "modules/Authentications/network";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import i18n from "localization/i18next";
import { changeLang, changeLangByUser } from "store/lang";
import { changeLanguage } from "modules/components/network";

const Watchers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [breakPointData, setBreakPointData] = useState();
  const [userAccounts, setUserAccounts] = useState([]);
  const [isParentOnline, setIsParentOnline] = useState(true);
  const [hoveredEl, setHoveredEl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    auth: { authorization },
    lang: { lang },
  } = useSelector((state) => ({
    auth: state.auth,
    lang: state.lang,
  }));

  const isOnline = authorization?.online;
  const parentStatus = authorization?.online?.isParent;
  const { pathname } = location;
  useEffect(() => {
    if (location?.state?.mode === "forgetPinCode") {
      window.history.forward();
      window.addEventListener("popstate", () => {
        window.history.forward();
      });
    }
  }, [location?.state?.mode]);

  useEffect(() => {
    const payload = {
      userId: authorization._id,
      lang,
    };
    setIsLoading(true);
    getUserAccounts(
      payload,
      (success) => {
        setUserAccounts(success.data.userAccounts);
        setIsLoading(false);
      },
      (fail) => {
        setIsLoading(false);
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  }, [authorization?._id, lang]);

  useEffect(() => {
    if (isOnline && !parentStatus) setIsParentOnline(false);
    if (isOnline && parentStatus) setIsParentOnline(true);
  }, [parentStatus]);

  // useEffect(() => {
  //   if (authorization?.online && authorization?.online?.preferedLanguage) {
  //     console.log("FROM HOME ABBBBB");
  //     getAllLanguages(
  //       { lang },
  //       (success) => {
  //         const [language] = success.data.filter((language) => {
  //           return language.id === authorization?.online.preferedLanguage;
  //         });
  //         console.log("🚀~ language:", language);

  //         let userLanguage =
  //           language.name === "Arabic" || language.name === "اللغة العربية"
  //             ? "ar"
  //             : "en";

  //         let data = {
  //           accountId: authorization?.online?.id,
  //           lang: userLanguage,
  //           accountAppLanguage: userLanguage,
  //         };
  //         console.log("🚀~ DATA:", data);
  //         if (navigator.onLine) {
  //           changeLanguage(
  //             data,
  //             (success) => {},
  //             (fail) => {}
  //           );
  //         }

  //         i18n.changeLanguage(userLanguage);
  //         dispatch(changeLang(userLanguage));
  //         getCurrentLanguageToService(userLanguage);
  //         // dispatch(changeLang(userLanguage === "en" ? "ar" : "en"));
  //       },
  //       (fail) => {
  //         // setAllLanguages("No Data");
  //       }
  //     );
  //   }
  // }, [authorization?.online?.preferedLanguage]);

  const enterToAccount = (selectedUser) => {
    // Related to change language associated with prefered language
    // dispatch(changeLangByUser(false));
    // Check if Selected User is parent or not
    selectedUser.id = selectedUser._id;

    if (!selectedUser.isParent) {
      dispatch(onlineUser(selectedUser));

      if (authorization?.online?._id) {
        const payload = {
          deviceId: authorization.deviceId,
          userId: authorization?._id,
          accountId: authorization?.online?._id,
        };

        logoutUserAccount(
          payload,
          (success) => {
            navigate(ROUTES.HOME, {
              state: {
                splashScreen: true,
                selectedUser: true,
                mode: "enter",
                online: selectedUser,
              },
            });
          },
          (fail) => {}
        );
      } else {
        navigate(ROUTES.HOME, {
          state: {
            splashScreen: true,
            selectedUser: true,
            mode: "enter",
            online: selectedUser,
          },
        });
      }
    }

    if (selectedUser.isParent && authorization?.pinCode) {
      navigate(ROUTES.VERIFY_PARENTAL_CODE, {
        state: {
          userData: selectedUser,
          mode: "enter",
        },
      });
    }

    if (selectedUser.isParent && !authorization?.pinCode) {
      navigate(ROUTES.CREATE_PARENTAL_CODE, {
        state: {
          userData: selectedUser,
          mode: "enter",
        },
      });
    }
  };

  const addChildUser = () => {
    const parentAccount = userAccounts.find((acc) => acc.isParent);
    parentAccount.id = parentAccount._id;

    if (userAccounts?.length >= 5) {
      dispatch(resetChildData());
      toastError(t("cantAddMoreThanFour"));
      return;
    }

    if (authorization?.pinCode) {
      dispatch(addChildFrom(pathname));
      navigate(ROUTES.VERIFY_PARENTAL_CODE, {
        state: {
          userData: parentAccount,
          mode: "add",
          from: pathname,
        },
      });
    }

    if (!authorization?.pinCode) {
      navigate(ROUTES.CREATE_PARENTAL_CODE, {
        state: {
          userData: parentAccount,
          mode: "add",
          from: pathname,
        },
      });
    }
  };

  function onMouseOver(_idx) {
    setHoveredEl(_idx);
  }

  function onMouseLeave() {
    setHoveredEl(null);
  }

  return (
    <section className="watchers ">
      <Container>
        <DetectWindowSize
          getBreakPointData={(data) => setBreakPointData(data)}
        />
        {/* <figure className="single-centered-logo text-center">
          <Link to="/">
            <img src={AmuseLogo} alt="amuse the kids" />
          </Link>
        </figure> */}

        <div className="single-centered-title text-center mt-5">
          {t("selectUser")}
        </div>

        <div className={`watchers__allAvatars justify-content-evenly`}>
          <>
            {isLoading ? (
              <>
                {[null, null, null, null].map((item, _idx) => {
                  return (
                    <div className="watchers__avatar" key={_idx}>
                      <figure className="watchers__figure">
                        <Skeleton width={"100%"} height={"100%"} circle />
                        {/* <img
                          src={camelAvatar}
                          alt='Normal Avatar'
                          className='camel-avatar'
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        /> */}
                      </figure>
                      <Skeleton
                        className="mt-4"
                        height={"1.25rem"}
                        width={"100px"}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {userAccounts?.map((userAccount) => {
                  return (
                    <div
                      className="watchers__avatar text-center"
                      key={userAccount._id}
                    >
                      <figure
                        className="watchers__figure"
                        onMouseOver={() => onMouseOver(userAccount._id)}
                        onMouseLeave={() => onMouseLeave()}
                        onClick={() => {
                          enterToAccount(userAccount);
                        }}
                      >
                        <Image
                          wrapperClassName={"image-wrapper"}
                          src={addTokenToMedia(baseUrl + userAccount.avatar)}
                          alt="avatar"
                          role="button"
                          className="watchers__avatar__image"
                        />
                      </figure>
                      <h3
                        role={"button"}
                        style={{
                          color:
                            userAccount?._id === hoveredEl ||
                            authorization?.online?._id === userAccount?._id
                              ? "#356ab3"
                              : "#c1b7b7",
                        }}
                        onClick={() => {
                          enterToAccount(userAccount);
                        }}
                        className="watchers__avatar__name mt-4"
                      >
                        {t(`${userAccount.name}`)}
                      </h3>
                    </div>
                  );
                })}

                {/* {!isLoading && isParentOnline && !isOnline && (
                  <div className="watchers__avatar text-center">
                    <figure
                      role={"button"}
                      onClick={addChildUser}
                      className="watchers__figure"
                    >
                      <Image
                        wrapperClassName={"image-wrapper"}
                        src={addAcount}
                        alt="avatar"
                        role="button"
                      />
                    </figure>

                    <h3
                      className="watchers__avatar__name mt-4"
                      role={"button"}
                      onClick={addChildUser}
                    >
                      {t("Add")}
                    </h3>
                  </div>
                )} */}
              </>
            )}
          </>
        </div>

        <div
          className="watchers__manage text-center "
          style={{ marginBottom: "2rem" }}
        >
          <Link className="watchers__manage-text " to={ROUTES.MANAGE_ACCOUNT}>
            {t("manageYourAccount")}
          </Link>
        </div>
        {/* {authorization?.online?.isParent && (
         
        )} */}
      </Container>
    </section>
  );
};

export default Watchers;
