import i18next from "localization/i18next";
import { useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getCurrentLanguageToService, getToken } from "services";
// componentss
import Routes from "modules/Routes";
import Footer from "modules/components/Footer";
// import NewNavbar from "modules/components/NewNavbar";
import MainHeader from "modules/components/MainHeader";

// Styling
import i18n from "localization/i18next";
import { useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "render-smooth-image-react/build/style.css";
import { changeLang } from "store/lang";
import "./App.scss";
import "./firebase";
import { SkeletonTheme } from "react-loading-skeleton";

const queryClient = new QueryClient();

function App({ getCurrentDir }) {
  const { lang, dir, userChangeLng } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const lng = !searchParams.get("lang") ? null : searchParams.get("lang");

  function changeLangAction(language = lng) {
    i18n.changeLanguage(language);
    dispatch(changeLang(language));
    // getCurrentLanguageToService(language);
  }
  useLayoutEffect(() => {
    if (lng) {
      changeLangAction();
    }
  }, [lng]);
  // const isLiveOrPreprod = false;
  const isLiveOrPreprod = process.env.REACT_APP_ENV === "live";
  //Disable inspect menu
  useEffect(() => {
    const disableF12Btn = (e) => {
      if (e.key === "F12") e.preventDefault();
    };
    if (isLiveOrPreprod) {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });

      document.addEventListener("keydown", disableF12Btn);
    }
    return () => {
      document.removeEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      document.removeEventListener("keydown", disableF12Btn);
    };
  });
  getToken(authorization?.token);

  getCurrentLanguageToService(lang);

  useEffect(() => {
    i18next.init({
      lng: lang,
      fallbackLng: lang,
    });
    getCurrentDir(dir);
    //eslint-disable-next-line
  }, [lang, dir]);

  // Related to change language associated with prefered language
  // useEffect(() => {
  //   if (
  //     authorization?.online &&
  //     authorization?.online?.preferedLanguage &&
  //     !userChangeLng
  //   ) {
  //     console.log("🚀FROM_HOME");
  //     getAllLanguages(
  //       { lang },
  //       (success) => {
  //         const [language] = success.data.filter((language) => {
  //           return language.id === authorization?.online.preferedLanguage;
  //         });
  //         console.log("🚀LANGUAGE:", language);

  //         let userLanguage =
  //           language.name === "Arabic" || language.name === "اللغة العربية"
  //             ? "ar"
  //             : "en";

  //         console.log("🚀USERLANGUAGE:", userLanguage);

  //         changeLangAction(userLanguage);

  //         // let data = {
  //         //   accountId: authorization?.online?.id,
  //         //   lang: userLanguage,
  //         //   accountAppLanguage: userLanguage,
  //         // };
  //         // console.log("🚀~ DATA:", data);
  //         // if (navigator.onLine) {
  //         //   changeLanguage(
  //         //     data,
  //         //     (success) => {
  //         //       console.log("🚀SUCCESS");
  //         //     },
  //         //     (fail) => {
  //         //       console.log("🚀FAIL");
  //         //     }
  //         //   );
  //         // }

  //         // changeLangAction(userLanguage);

  //         // i18n.changeLanguage(userLanguage);
  //         // dispatch(changeLang(userLanguage));
  //         // getCurrentLanguageToService(userLanguage);
  //         // dispatch(changeLang(userLanguage === "en" ? "ar" : "en"));
  //       },
  //       (fail) => {
  //         // setAllLanguages("No Data");
  //       }
  //     );
  //   }
  // }, [authorization?.online?.preferedLanguage, userChangeLng]);

  useEffect(() => {
    const storedLang = localStorage.getItem(`${authorization?.online?.id}`);
    const lang = localStorage.getItem("lang");
    if (authorization?.online && storedLang) {
      changeLangAction(storedLang);
    } else {
      changeLangAction(lang);
    }
  }, [authorization?.online]);

  return (
    <QueryClientProvider client={queryClient}>
      {/* <NoInternet> */}
      <>
        <Helmet>
          <html lang={lang} dir={dir}></html>
        </Helmet>
        <ToastContainer dir={dir} />
        <div className="app-container">
          <SkeletonTheme baseColor="#222" highlightColor="#222">
            {/* <NewNavbar /> */}
            <MainHeader />
            <Routes />
            <Footer />
          </SkeletonTheme>
        </div>
      </>
      {/* </NoInternet> */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
