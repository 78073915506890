import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import closeIcon from "assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import ROUTES from "constants/_routes";
import { useSelector } from "react-redux";

function SubscribenowModal({ open, onCancel }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { authorization } = useSelector((state) => state.auth);
  const isParentOnline = authorization?.online?.isParent;

  return (
    <Modal
      centered
      open={open}
      width={498}
      onCancel={onCancel}
      className="cannotChangePlanModal"
      footer={false}
      closeIcon={
        <img
          src={closeIcon}
          alt="icon"
          className=""
          width="20px"
          height="20px"
        />
      }
    >
      <div className="">
        <p
          className="para"
          style={{
            color: "#356AB3",
            marginBottom: `${isParentOnline ? "2rem" : ""}`,
          }}
        >
          {authorization?.token
            ? isParentOnline
              ? t("pleaseSubscribeFirstParent")
              : t("pleaseSubscribeFirstChild")
            : t("loginFirst")}
        </p>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isParentOnline && (
          <Button
            className="button-primary button-primary--joinCompetition"
            onClick={(e) => {
              navigate(ROUTES.SUBSCRIPTION);
              onCancel();
            }}
          >
            {t("subscribeNowWithout")}
          </Button>
        )}
        {!authorization._id && (
          <Button
            className="button-primary button-primary--joinCompetition"
            onClick={(e) => {
              navigate(ROUTES.SIGNIN);
            }}
          >
            {t("signin")}
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default SubscribenowModal;
