import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { getCategoriesByGenreId } from "../network";
import VideoListItem from "modules/Shared/VideoListItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import shortid from "shortid";

function GeneriesVideos({ selectedGenereId }) {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showArrow, setShowArrow] = useState(false);
  const arr = new Array(2).fill(0);
  const arrOfVideos = new Array(5).fill(0);
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const showHideArrow = (state) => {
    if (state === "show") {
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  };
  //
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 1921 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 1920, min: 1200 },
  //     // breakpoint: { max: 3000, min: 1440 },
  //     // breakpoint: { max: 3000, min: 1024 },
  //     items: 5,
  //   },
  //   desktopSm: {
  //     breakpoint: { max: 1199, min: 768 },
  //     // breakpoint: { max: 1439, min: 768 },
  //     // breakpoint: { max: 1023, min: 768 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 767, min: 481 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 480, min: 0 },
  //     items: 1,
  //   },
  // };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 5, // Show only 5 items at a time
    },
    desktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const payload = {
      lang,
      genreId: selectedGenereId,
      ...(authorization?.online?.id && {
        accountId: authorization?.online?.id,
      }),
    };

    setIsLoading(true);

    getCategoriesByGenreId(
      payload,
      (success) => {
        if (success.isSuccess) {
          const allCategories = [...success.data];
          setCategories(allCategories);
          setIsLoading(false);
        }
      },
      (fail) => {
        setIsLoading(false);
      }
    );
  }, [selectedGenereId, lang, authorization?.online?.id]);
  return (
    <section className="generies-videos">
      <Container fluid className="custom-container">
        <div className="generies-videos__container ">
          {!isLoading ? (
            categories.length ? (
              categories.map((category, i) => {
                //
                return (
                  <div
                    className="generies-videos__item "
                    //FIXME category id is undefined on the first genre
                    key={i}
                  >
                    <Container
                      fluid
                      className="custom-container"
                      style={{ paddingInline: "0" }}
                    >
                      <header className="main-header item__header">
                        <h2>{category.category}</h2>
                      </header>

                      <div style={{ height: "auto" }}>
                        {category?.headers?.length ? (
                          <Carousel
                            rtl={lang === "ar" ? true : false}
                            arrows={true}
                            responsive={responsive}
                            keyBoardControl
                            showDots={false}
                            containerClass="generies-videos__carousel-container"
                            dotListClass="generies-videos__dots"
                            infinite={false}
                          >
                            {category.headers.map((header, _) => {
                              return (
                                <div
                                  className="video-list-item__home-item-container"
                                  key={header._id}
                                >
                                  <VideoListItem
                                    details={{
                                      header,
                                      isContinueWatching:
                                        category.isContinueWatching,
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </Carousel>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Container>
                  </div>
                );
              })
            ) : (
              <div className="generies-videos__no-item">{t("noVedios")}</div>
            )
          ) : (
            arr.map((_, index) => (
              //FIXME THE KEY MUST BE UNIQU SO THE INDEX IS NOT UNIQUE BUT THIS TEMP SOL
              <div
                className="generies-videos__item"
                key={index}
                style={{ marginBlock: "2rem" }}
              >
                <Container
                  fluid
                  className="custom-container"
                  style={{ paddingInline: "0" }}
                >
                  <header className="generies-videos__header d-flex justify-content-between align-items-center">
                    <h2>
                      <Skeleton height={"1em"} width={148} />
                    </h2>
                  </header>
                  <div className="item__container">
                    <Row
                      gutter={[20, 20]}
                      className="item__video"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      {arrOfVideos?.map((video, i) => (
                        <Col xs={24} md={4} key={i}>
                          <Skeleton
                            // width={"217px"}
                            borderRadius={25}
                            style={{
                              aspectRatio: "4/3",
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Container>
              </div>
            ))
          )}
        </div>
      </Container>
    </section>
  );
}

export default GeneriesVideos;
