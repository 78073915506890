import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import Controllers from "./M3u8Components/Controllers";
import { LoadingSpinner } from "./VideoComponents/LoadingSpinner";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import { baseUrl } from "services";
import { checkVideoFree, continueWatching, sendVideoViews } from "../network";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "constants/_routes";
import SubscribenowModal from "./SubscribenowModal";
import ReplayIcon from "assets/video-controllers/ReplayIcon";
// import { addView } from "store/viewsData";

const validVideoPaths = [
  "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8",
  "https://devstreaming-cdn.apple.com/videos/streaming/examples/adv_dv_atmos/main.m3u8?ref=developerinsider.co",
  "https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_adv_example_hevc/master.m3u8?ref=developerinsider.co",
  "https://test-streams.mux.dev/tos_ismc/main.m3u8?ref=developerinsider.co",
];

function VideoShowM3u8({
  detailsData,
  relatedPartsData,
  getCurrentSeasonId,
  seriesOrParts,
  // isFree,
  // setSelectedVideo,
}) {
  const [hls, setHls] = useState(null);
  const [videoData, setVideoData] = useState({
    audioTracks: [],
    videoQualities: [],
    subtitles: [],
  });
  const [videoPath, setVideoPath] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isBuffering, setIsBuffering] = useState(false);
  const [volume, setVolume] = useState(1); // Volume state
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [viewOverlay, setViewOverlay] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [showSubscribeNowModal, setshowSubscribeNowModal] = useState(false);
  const [showControls, setshowControls] = useState(true);
  const [videoIsReady, setVideoIsReady] = useState(false);

  const [selectedQuality, setSelectedQuality] = useState(-1);
  const [selectedAudio, setSelectedAudio] = useState(-1);
  const [selectedSub, setSelectedSub] = useState(-1);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [videoHeaderId, videoId, isSeries, showFullScreen] =
    params.qp.split("-");

  const videoRef = useRef();
  const videoFullScreenWrapper = useRef();

  const videoElement = document.getElementById("video");

  const { authorization } = useSelector((state) => state.auth);
  const { views } = useSelector((state) => state.viewsData);
  const isPaid = authorization?.isPaid?.isPaid;

  const currentRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFullScreenChange = () => {
    if (!document.fullscreenElement) {
      videoFullScreenWrapper?.current?.requestFullscreen();
      return;
    }
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
  };

  useEffect(() => {
    if (showFullScreen === "true") {
      onFullScreenChange();
    }
    window.scrollTo(0, 0);
  }, []);

  // Check is free video
  useEffect(() => {
    if (navigator.onLine) {
      checkVideoFree(videoId, (success) => {
        setIsFree(success.data.isFree);
        if (!success.data.isFree && !isPaid) setshowSubscribeNowModal(true);
      });
    }
  }, [videoId]);

  useEffect(() => {
    if (!isFree && !isPaid) {
      videoRef.current?.pause();
      setshowControls(false);
    } else {
      setshowControls(true);
    }
  }, [isFree, isPaid]);

  // Set video path
  useEffect(() => {
    if (detailsData) {
      setVideoPath(detailsData?.videoPath);
    }
  }, [detailsData]);

  // Fetch m3u8
  // useEffect(() => {
  //   const videoElement = document.getElementById("video");
  //   if (Hls.isSupported() && videoPath) {
  //     const hlsInstance = new Hls();

  //     if (validVideoPaths.includes(videoPath)) {
  //       console.log("Included!");
  //       hlsInstance.loadSource(videoPath);
  //     } else {
  //       console.log("Not Included!");
  //       hlsInstance.loadSource(addTokenToMedia(baseUrl + videoPath));
  //     }
  //     hlsInstance.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
  //       const audioTracks = data.audioTracks.map((audio) => ({
  //         id: audio.id,
  //         label: audio.name,
  //       }));

  //       const subtitleTracks = data.subtitleTracks.map((subtitle) => ({
  //         id: subtitle.id,
  //         label: subtitle.name,
  //       }));

  //       const videoQualities = data.levels.map((level, index) => ({
  //         height: level.height,
  //         index: index,
  //       }));

  //       setVideoData({
  //         audioTracks,
  //         videoQualities,
  //         subtitles: subtitleTracks,
  //       });

  //       setHls(hlsInstance);
  //     });

  //     hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
  //       videoElement.addEventListener("loadedmetadata", () => {
  //         setDuration(videoElement.duration);
  //         setVideoIsReady(true);
  //       });
  //     });

  //     hlsInstance.attachMedia(videoElement);

  //     return () => {
  //       videoElement.removeEventListener("loadedmetadata", () => {
  //         setDuration(videoElement.duration);
  //       });
  //     };
  //   }
  // }, [videoPath]);

  // Convert hexadecimal key to Uint8Array

  const hexKey = "00112233445566778899aabbccddeeff";
  const decryptionKey = new Uint8Array(
    hexKey.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );

  useEffect(() => {
    const videoElement = document.getElementById("video");
    if (Hls.isSupported() && videoPath) {
      const hlsInstance = new Hls();

      // fetch(baseUrl + videoPath)
      //   .then((response) => response.text())
      //   .then((text) => {
      //     // Extract key from M3U8 content
      //     const keyMatch = text.match(/KEY\s+(?:URI|METHOD):(.+)/i);
      //     let encryptionKey = "";
      //     if (keyMatch && keyMatch[1]) {
      //       encryptionKey = keyMatch[1];
      //     }
      //     console.log("TTTTTTTTTexttttttt", text);
      //     // Now load the M3U8 with Hls.js
      //     hlsInstance.loadSource(text);
      //   })
      //   .catch((error) => {
      //     console.error("Error fetching M3U8:", error);
      //     // Handle the error appropriately
      //   });

      if (validVideoPaths.includes(videoPath)) {
        hlsInstance.loadSource(videoPath);
      } else {
        hlsInstance.loadSource(
          addTokenToMedia(
            `${baseUrl}${videoPath}?key=00112233445566778899aabbccddeeff`
          )
        );
      }

      hlsInstance.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        const uniqueAudioTracks = Array.from(
          new Set(data.audioTracks.map((audio) => audio.id))
        )
          .map((id) => data.audioTracks.find((audio) => audio.id === id))
          .map((audio) => ({
            id: audio.id,
            label: audio.name,
          }));

        const uniqueSubtitles = Array.from(
          new Set(data.subtitleTracks.map((subtitle) => subtitle.id))
        )
          .map((id) =>
            data.subtitleTracks.find((subtitle) => subtitle.id === id)
          )
          .map((subtitle) => ({
            id: subtitle.id,
            label: subtitle.name,
          }));

        const uniqueVideoQualities = Array.from(
          new Set(data.levels.map((level) => level.height))
        )
          .map((height) => data.levels.find((level) => level.height === height))
          .map((level, index) => ({
            height: level.height,
            index: index,
          }));

        setVideoData({
          audioTracks: uniqueAudioTracks,
          videoQualities: uniqueVideoQualities,
          subtitles: uniqueSubtitles,
        });

        setHls(hlsInstance);
      });

      hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
        videoElement.addEventListener("loadedmetadata", () => {
          setDuration(videoElement.duration);
          setVideoIsReady(true);
        });
      });

      hlsInstance.attachMedia(videoElement);

      return () => {
        videoElement.removeEventListener("loadedmetadata", () => {
          setDuration(videoElement.duration);
        });
        hlsInstance.destroy(); // Clean up Hls instance
      };
    }
  }, [videoPath]);

  // useEffect(() => {
  //   const videoElement = document.getElementById("video");
  //   if (Hls.isSupported() && videoPath) {
  //     const hlsInstance = new Hls({
  //       // Custom key loader for static decryption key
  //       keyLoader: {
  //         load: (keyUri, onSuccess, onError) => {
  //           console.log("Attempting to load decryption key for URI:", keyUri);
  //           try {
  //             console.log("Decryption key:", decryptionKey);
  //             // Pass the static key directly to the onSuccess callback
  //             onSuccess({
  //               data: decryptionKey, // Use the converted Uint8Array key here
  //               uri: keyUri,
  //             });
  //           } catch (error) {
  //             console.error("Error in key loading:", error);
  //             onError(error);
  //           }
  //         },
  //       },
  //     });

  //     // Set up video loading and quality handling as before
  //     if (validVideoPaths.includes(videoPath)) {
  //       hlsInstance.loadSource(videoPath);
  //     } else {
  //       hlsInstance.loadSource(addTokenToMedia(baseUrl + videoPath));
  //     }

  //     hlsInstance.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
  //       const uniqueAudioTracks = Array.from(
  //         new Set(data.audioTracks.map((audio) => audio.id))
  //       )
  //         .map((id) => data.audioTracks.find((audio) => audio.id === id))
  //         .map((audio) => ({
  //           id: audio.id,
  //           label: audio.name,
  //         }));

  //       const uniqueSubtitles = Array.from(
  //         new Set(data.subtitleTracks.map((subtitle) => subtitle.id))
  //       )
  //         .map((id) =>
  //           data.subtitleTracks.find((subtitle) => subtitle.id === id)
  //         )
  //         .map((subtitle) => ({
  //           id: subtitle.id,
  //           label: subtitle.name,
  //         }));

  //       const uniqueVideoQualities = Array.from(
  //         new Set(data.levels.map((level) => level.height))
  //       )
  //         .map((height) => data.levels.find((level) => level.height === height))
  //         .map((level, index) => ({
  //           height: level.height,
  //           index: index,
  //         }));

  //       setVideoData({
  //         audioTracks: uniqueAudioTracks,
  //         videoQualities: uniqueVideoQualities,
  //         subtitles: uniqueSubtitles,
  //       });

  //       setHls(hlsInstance);
  //     });

  //     hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
  //       videoElement.addEventListener("loadedmetadata", () => {
  //         setDuration(videoElement.duration);
  //         setVideoIsReady(true);
  //       });
  //     });

  //     hlsInstance.attachMedia(videoElement);

  //     return () => {
  //       videoElement.removeEventListener("loadedmetadata", () => {
  //         setDuration(videoElement.duration);
  //       });
  //       hlsInstance.destroy(); // Clean up Hls instance
  //     };
  //   }
  // }, [videoPath]);

  // UseEffect with logging
  // useEffect(() => {
  //   const videoElement = document.getElementById("video");
  //   if (Hls.isSupported() && videoPath) {
  //     const hlsInstance = new Hls({
  //       keyLoader: {
  //         load: (keyUri, onSuccess, onError) => {
  //           console.log("Attempting to load decryption key for URI:", keyUri);
  //           try {
  //             console.log("Decryption key:", decryptionKey);
  //             onSuccess({
  //               data: decryptionKey,
  //               uri: keyUri,
  //             });
  //           } catch (error) {
  //             console.error("Error in key loading:", error);
  //             onError(error);
  //           }
  //         },
  //       },
  //     });

  //     hlsInstance.loadSource(videoPath);
  //     hlsInstance.attachMedia(videoElement);

  //     hlsInstance.on(Hls.Events.ERROR, (event, data) => {
  //       if (data.fatal) {
  //         console.error("Fatal error:", data);
  //       } else {
  //         console.warn("Non-fatal error:", data);
  //       }
  //       if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
  //         console.error("Network error:", data);
  //       } else if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
  //         console.error("Media error:", data);
  //       } else {
  //         console.error("HLS error:", data);
  //       }
  //     });

  //     return () => {
  //       hlsInstance.destroy();
  //     };
  //   }
  // }, [videoPath]);

  // Reset video before playing
  useEffect(() => {
    if (hls) {
      hls.subtitleTrack = -1;
    }

    if (detailsData?.lastSeenTime !== undefined && duration) {
      const lastSeen = +detailsData?.lastSeenTime / 1000;

      if (Math.ceil(lastSeen) >= Math.round(duration)) {
        handleSeekTo(0);
      }

      if (Math.ceil(lastSeen) < Math.round(duration)) {
        handleSeekTo(+detailsData?.lastSeenTime);
      }

      if (Math.ceil(lastSeen) === 0) {
        handleSeekTo(0);
      }
    }
  }, [hls, duration]);

  // handle full screen
  useEffect(() => {
    const handleChangeFullScreen = () => {
      setIsFullScreen(document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleChangeFullScreen);

    return () =>
      document.addEventListener("fullscreenchange", handleChangeFullScreen);
  }, [setIsFullScreen]);

  // Continue watching
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        currentRef.current !== null &&
        detailsData?.videoId &&
        isPlaying &&
        !isBuffering
      ) {
        const data = {
          LastTime: currentRef.current * 1000,
          userAccountId: authorization.online._id,
          videoId: detailsData.videoId,
        };
        // const viewsInfo = {
        //   videoId: detailsData.videoId,
        //   accountId: authorization.online._id,
        //   numberOfMinutes: Math.round(currentRef.current / 60),
        // };
        // dispatch(addView(viewsInfo));
        continueWatching(
          data,
          (success) => {},
          (fail) => {}
        );
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [videoPath, isPlaying]);

  // // Send views
  // useEffect(() => {
  //   return () => {
  //     sendVideoViews(
  //       views,
  //       (success) => {},
  //       (fail) => {}
  //     );
  //   };
  // }, []);

  const currentSeasonIndex = relatedPartsData.findIndex((season) =>
    season.videos?.find((video) => video.videoId === detailsData.videoId)
  );

  const currentVideoIndex = relatedPartsData[currentSeasonIndex]?.seasonName
    ? relatedPartsData[currentSeasonIndex].videos.find(
        (video) => video.videoId === detailsData.videoId
      )?.videoIndex
    : relatedPartsData.find((video) => video.videoId === detailsData.videoId)
        ?.videoIndex;

  const isNotFirstVideoInSeries = relatedPartsData[currentSeasonIndex]
    ?.seasonName
    ? !(
        parseInt(currentSeasonIndex) === 0 &&
        parseInt(currentVideoIndex) - 1 === 0
      )
    : parseInt(currentVideoIndex) - 1 !== 0;

  const isNotLastVideoInSeries = relatedPartsData[currentSeasonIndex]
    ?.seasonName
    ? !(
        currentSeasonIndex === relatedPartsData.length - 1 &&
        relatedPartsData[currentSeasonIndex]?.videos?.length ===
          currentVideoIndex
      )
    : relatedPartsData.length !== currentVideoIndex;

  function selectVideo(videoId) {
    let qpData = [videoHeaderId, videoId, isSeries].join("-");
    let qp = {
      qp: qpData,
    };
    setIsEnded(false);
    // handleChangeSubtitles(0);
    navigate({
      pathname: ROUTES.WATCH_NOW,
      search: `?${createSearchParams(qp)}`,
    });
  }

  function selectPrevVideo() {
    // sendVideoViews(
    //   views,
    //   (success) => {},
    //   (fail) => {}
    // );
    if (relatedPartsData[currentSeasonIndex]?.seasonName) {
      let currentSeasonIndexVar = currentSeasonIndex;
      let currentVideoIndexVar = currentVideoIndex;
      if (currentVideoIndexVar - 1 === 0 && currentSeasonIndexVar !== 0) {
        currentSeasonIndexVar -= 1;
        // setCurrentSeasonIndex(currentSeasonIndexVar);
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[
            relatedPartsData[currentSeasonIndexVar].videos.length - 1
          ].videoId
        );
        getCurrentSeasonId(seriesOrParts[currentSeasonIndexVar]._id);
      } else {
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[currentVideoIndex - 2]
            .videoId
        );
      }
    } else {
      selectVideo(relatedPartsData[currentVideoIndex - 2].videoId);
    }
  }

  function selectNextVideo() {
    let currentSeasonIndexVar = currentSeasonIndex;
    let currentVideoIndexVar = currentVideoIndex;

    // sendVideoViews(
    //   views,
    //   (success) => {},
    //   (fail) => {}
    // );

    if (relatedPartsData[currentSeasonIndexVar]?.seasonName) {
      if (
        relatedPartsData[currentSeasonIndexVar]?.videos?.length ===
          currentVideoIndexVar &&
        currentSeasonIndexVar !== relatedPartsData.length - 1
      ) {
        currentSeasonIndexVar += 1;
        // setCurrentSeasonIndex(currentSeasonIndexVar);
        selectVideo(relatedPartsData[currentSeasonIndexVar].videos[0].videoId);

        getCurrentSeasonId(seriesOrParts[currentSeasonIndexVar]._id);
      } else {
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[currentVideoIndexVar]
            .videoId
        );
      }
    } else {
      selectVideo(relatedPartsData[currentVideoIndexVar]?.videoId);
    }
  }

  const handleAutoPlayNextVideo = () => {
    if (
      relatedPartsData[currentSeasonIndex]?.seasonName
        ? !(
            relatedPartsData[currentSeasonIndex]?.videos?.length ===
              currentVideoIndex &&
            currentSeasonIndex === relatedPartsData.length - 1
          )
        : relatedPartsData.length !== currentVideoIndex
    ) {
      selectNextVideo();
    } else {
      // sendVideoViews(
      //   views,
      //   (success) => {},
      //   (fail) => {}
      // );
    }
  };

  const handleTimeUpdate = (e) => {
    setCurrentTime(videoRef.current.currentTime * 1000);
    const currentTime = e.target.currentTime;
    currentRef.current = currentTime;
  };

  const handleVideoEnded = () => {
    setIsEnded(true);
    setIsPlaying(false);
    const data = {
      LastTime: 0,
      userAccountId: authorization.online._id,
      videoId: detailsData.videoId,
    };
    continueWatching(
      data,
      (success) => {},
      (fail) => {}
    );
    handleAutoPlayNextVideo();
    setCurrentTime(0);
  };

  const handleQualityChange = (index) => {
    if (hls) {
      hls.currentLevel = index;
    }
  };

  const handleAudioTrackChange = (trackId) => {
    if (hls) {
      hls.audioTrack = trackId;
    }
  };

  const handleSubtitleChange = (trackId) => {
    console.log("🚀 ~ handleSubtitleChange ~ trackId:", trackId);
    if (hls) {
      hls.subtitleTrack = trackId;
    }
  };

  const handleSeekTo = (time) => {
    if (!isFree && !isPaid) return;
    videoElement.currentTime = time / 1000;
    videoElement?.play();
    // if (isEnded) {
    //   setIsEnded(false);
    //   setIsPlaying(true);
    // }

    // if (hls) {
    //   hls.media.currentTime = time / 1000;
    // }
  };

  const handleSkipForward = () => {
    if (hls) {
      const currentTime = hls.media.currentTime;
      hls.media.currentTime = currentTime + 10;
      setCurrentTime((currentTime + 10) * 1000);
    }
  };

  const handleSkipBackward = () => {
    if (hls) {
      const currentTime = hls.media.currentTime;
      hls.media.currentTime = Math.max(currentTime - 10, 0);
      setCurrentTime((currentTime - 10) * 1000);
    }
  };

  const handleMute = () => {
    // if (hls) {
    //   hls.loadLevel = 0;
    // }
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  const handleVolumeChange = (newVolume) => {
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
      setVolume(newVolume);
    }
  };

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      videoFullScreenWrapper?.current?.requestFullscreen();
      return;
    }
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
  };

  const handleVideoPlaying = () => {
    setIsBuffering(false);
    setIsPlaying(true);
    setIsEnded(false);
  };

  const handleVideoWaiting = () => {
    setIsBuffering(true);
    setIsPlaying(false);
  };

  const handleReplayVideo = () => {
    setVideoIsReady(true);
    if (hls) {
      setIsEnded(false);
      hls.media.currentTime = 0;
      videoRef.current.play();
    }
  };

  return (
    <div className="watch-now__video-show__wrapper">
      <div className="watch-now__video-show" ref={videoFullScreenWrapper}>
        <video
          autoPlay={!isPaid && !isFree ? false : true}
          onWaiting={handleVideoWaiting}
          onPlaying={handleVideoPlaying}
          onPause={() => setIsPlaying(false)}
          ref={videoRef}
          onEnded={handleVideoEnded}
          onTimeUpdate={handleTimeUpdate}
          controls
          id="video"
          poster={addTokenToMedia(baseUrl + detailsData?.imgPath)}
          style={{
            width: "100%",
          }}
        ></video>

        {viewOverlay && !isEnded && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: `${isBuffering ? "" : "rgba(0, 0, 0, 0.7)"}`,
            }}
          ></div>
        )}

        {!isFree && !isPaid && (
          <>
            <div class="overlay" key={isFree}>
              <div class="overlay-content"></div>
            </div>
            <SubscribenowModal
              open={showSubscribeNowModal}
              onCancel={() => setshowSubscribeNowModal(false)}
            />
          </>
        )}

        {(isBuffering || !videoIsReady) && !isEnded && <LoadingSpinner />}

        {isEnded && (
          <>
            <div class="overlay-replay" key={isFree}>
              <div class="overlay-content"></div>
            </div>
            <span
              onClick={handleReplayVideo}
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                cursor: "pointer",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ReplayIcon />
            </span>
          </>
        )}

        {showControls && videoIsReady && (
          <Controllers
            videoData={{ ...videoData, ...detailsData }}
            videoRef={videoRef}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            isEnded={isEnded}
            skipForward={handleSkipForward}
            skipBackward={handleSkipBackward}
            duration={duration}
            currentTime={currentTime}
            seekTo={handleSeekTo}
            isBuffering={isBuffering}
            changeAudio={handleAudioTrackChange}
            changeSubtitle={handleSubtitleChange}
            changeQuality={handleQualityChange}
            mutePlayer={handleMute}
            setVolume={handleVolumeChange}
            volume={volume}
            isFullScreen={isFullScreen}
            fullScreenChange={handleFullScreenChange}
            setViewOverlay={setViewOverlay}
            viewOverlay={viewOverlay}
            isNotFirstVideoInSeries={isNotFirstVideoInSeries}
            isNotLastVideoInSeries={isNotLastVideoInSeries}
            selectNextVideo={selectNextVideo}
            selectPrevVideo={selectPrevVideo}
            handleReplayVideo={handleReplayVideo}
            selectedQuality={selectedQuality}
            setSelectedQuality={setSelectedQuality}
            selectedAudio={selectedAudio}
            setSelectedAudio={setSelectedAudio}
            selectedSub={selectedSub}
            setSelectedSub={setSelectedSub}
          />
        )}
      </div>
    </div>
  );
}

export default VideoShowM3u8;
