import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getMyPackages } from "modules/Subscription/network";
import { useSelector } from "react-redux";
import { useState } from "react";
import ROUTES from "constants/_routes";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import savingBadge from "../../../../assets/icons/payment_discount_percent.svg";
import { Col, Row } from "antd";
import Spinner from "modules/Shared/Spinner";

function MyPlan() {
  const [freeDays, setFreeDays] = useState([]);
  const [myPackages, updateMyPackages] = useState([]);
  const [onlyOnePackageHasSubscribed, setOnlyOnePackageHasSubscribed] =
    useState(false);
  const { lang } = useSelector((state) => state.lang);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, updateIsLoading] = useState(false);
  //
  //
  useEffect(() => {
    updateIsLoading(true);
    getMyPackages(
      { lang: lang },
      (success) => {
        let onlyPackages = success.data.filter((pak) => !pak?.isFreeDays);
        let onlyFreeDays = success.data.filter((pak) => pak?.isFreeDays);
        // let onlyOnePackage = success.data?.filter((pak) => pak?.currentPackage);
        updateMyPackages(onlyPackages);
        setFreeDays(onlyFreeDays);
        if (onlyPackages?.length === 1) {
          setOnlyOnePackageHasSubscribed(true);
        }
        updateIsLoading(false);
      },
      (fail) => {}
    );
  }, [lang]);

  return (
    <div className="myPlan text-center ">
      {/* {isLoading ? (
        <Spinner />
      ) : ( */}
      <>
        <Row
          className={`row-container `}
          justify={`${myPackages?.length === 1 ? "center" : ""}`}
        >
          {myPackages.map((myPackage, packIndex) => {
            return (
              <Col
                xs={24}
                md={myPackages?.length === 1 ? 24 : 12}
                className=" d-flex align-items-center justify-content-center"
                key={packIndex}
              >
                {" "}
                <div
                  className={`myPlan-container mx-4 
                `}
                >
                  {myPackage.discount !== 0 && (
                    <div className="discount-box">
                      <div className="discount-container ">
                        <img
                          src={savingBadge}
                          alt="savingBadge"
                          className="saving-badge"
                        />
                        <div className="text">
                          {t("saved")} {myPackage.discount}%
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={`package-card ${
                      myPackage.currentPackage && "currentPackage"
                    }`}
                  >
                    <div className="package-name">{myPackage?.packageName}</div>
                    <div className="d-flex flex-column align-items-center package-amount ">
                      <span className="amount">{`${myPackage?.amount} ${t(
                        "EGP"
                      )}`}</span>
                      <div className="package-billedEvery">
                        {`${
                          myPackage?.billedEvery
                        } - ${myPackage?.amountPerMonth.toLowerCase()} / ${t(
                          "month"
                        )} `}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap px-2 mt-1 fs-16 fw-400">
                    {/* <div className="text-white">
                      <span className="text-red ">{t("startDate")}</span>{" "}
                      {moment(myPackage.startDate).format("DD/MM/YYYY")}
                    </div> */}
                    {/* <div className="text-white">
                      <span className="text-red ">{t("endDate")}</span>{" "}
                      {moment(myPackage.endDate).format("DD/MM/YYYY")}
                    </div> */}
                  </div>
                </div>
              </Col>
            );
          })}
          {freeDays?.length > 0 && (
            <>
              {freeDays.map((fDay, index) => {
                return (
                  <Col
                    xs={24}
                    md={freeDays?.length === 1 ? 24 : 12}
                    className=" d-flex align-items-center justify-content-center"
                    key={index}
                  >
                    <div
                      className={`myPlan-container mx-4 
                `}
                    >
                      <div
                        className={`package-card freeDays 
              `}
                      >
                        <div className="package-name">{`${fDay?.packageName}`}</div>
                        <div className="d-flex justify-content-between flex-wrap mt-1 fs-16 fw-400">
                          <div className="">
                            <span className="dark_blue">{t("from")}</span>{" "}
                            {moment(fDay?.startDate).format("DD/MM/YYYY")}
                          </div>
                          <div className="">
                            <span className="dark_blue">{t("to")}</span>{" "}
                            {moment(fDay?.endDate).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}{" "}
            </>
          )}
        </Row>
        <div
          className="mt-4 d-flex flex-column justify-content-center align-items-center"
          style={{ marginBottom: "2rem" }}
        >
          <button
            className="button-secondary btn-plan bg-white"
            onClick={() => {
              navigate(ROUTES.SUBSCRIPTION + "?title=changePlan");
            }}
          >
            {freeDays?.length > 0 && myPackages?.length === 0
              ? t("chooseYourPlan")
              : t("AllPlans")}{" "}
          </button>
          {onlyOnePackageHasSubscribed && (
            <span className="note">{t("changeMyPlanNote")}</span>
          )}
        </div>
      </>
      {/* // )} */}
    </div>
  );
}

export default MyPlan;
