const { createSlice } = require("@reduxjs/toolkit");

const viewsSlice = createSlice({
  name: "viewsData",
  initialState: {
    views: {},
  },
  reducers: {
    addView(state, action) {
      state.views = action.payload;
    },
  },
});

const { reducer, actions } = viewsSlice;
export const { addView } = actions;
export default reducer;
