import React, { useEffect, useState } from "react";
// import noInternet from "../../assets/images/no-internet.svg";
// import noInternetRedIcon from "../../assets/images/lostConntionIcon.svg";
import noInternetRedIcon from "../../assets/images/lost-connection.js";
import { Button } from "antd";
import amuseLogo from "../../assets/images/amuse-logo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";
import { useSelector } from "react-redux";

function NoInternetPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authorization } = useSelector((state) => state?.auth);
  const handleGoToDownloads = () => {
    navigate(ROUTES.MY_DOWNLOADS, { replace: true });
  };
  const noConnectionTitle = {
    color: "#ff4343",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "35px",
    marginTop: "3rem",
  };

  // useEffect(() => {
  //   const handleOnline = () => {
  //     navigate(-1, { replace: true });
  //   };
  //   window.addEventListener("online", handleOnline);
  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //   };
  // }, []);

  const handleTryAgain = () => {
    setLoading(true);
    if (navigator.onLine) {
      setTimeout(() => {
        navigate(-1, { replace: true });
      }, 1000);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div
      className="p-4 text-center"
      style={{ minHeight: "calc(100vh - 237px)" }}
    >
      <img src={amuseLogo} alt="amuseLogo" />
      <div style={noConnectionTitle}>
        <div>{t("noConnection")}</div>
      </div>
      <img
        className="my-4 no-connection-image"
        src={noInternetRedIcon}
        alt="noInternet"
      />
      <div className="text-center m-auto">
        <Button
          className="button-primary "
          onClick={handleTryAgain}
          loading={loading}
        >
          {t("tryAgain")}
        </Button>
      </div>
      {/* {authorization?.token && (
        <div className="text-center m-auto">
          <Button className="button-primary blue" onClick={handleGoToDownloads}>
            {t("goToDownloads")}
          </Button>
        </div>
      )} */}
    </div>
  );
}

export default NoInternetPage;
