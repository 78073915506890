import { Container } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import fbIcon from "assets/icons/facebook.svg";
import twIcon from "assets/icons/twitter.svg";
import instaIcon from "assets/icons/instagram.svg";
import tiktok from "assets/icons/tiktok.svg";
import ROUTES, { pagesNotHaveFooter } from "constants/_routes";
import { isMobile, isAndroid } from "react-device-detect";
const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname.includes(ROUTES.LOGOUT)) {
    return <></>;
  }
  const isWatchNowPage = location.pathname.includes(ROUTES.WATCH_NOW);
  if (pagesNotHaveFooter.includes(location.pathname)) {
    return <></>;
  } else {
    return (
      <footer className={`main-footer ${isWatchNowPage ? "" : "added-margin"}`}>
        <Container>
          <ul className="main-footer__ul">
            <li
              className="item cursor-pointer"
              onClick={() => {
                navigate(ROUTES.HOME, {
                  state: { scrollToTop: true },
                });
              }}
            >
              {t("home")}
            </li>
            <li
              className="item cursor-pointer"
              onClick={(e) => {
                if (!navigator.onLine) {
                  e.preventDefault();
                  alert(
                    t("YouareCurrentlyOfflinePleaseCheckYourInternetConnection")
                  );
                } else {
                  navigate(ROUTES.CONTACT_US);
                }
              }}
            >
              {t("contactUs")}
            </li>
            {isMobile && (
              <li className="item">
                <a
                  href={
                    isAndroid
                      ? "https://play.google.com/store/apps/details?id=com.amusethekids&hl=en"
                      : "https://apps.apple.com/bj/app/amuse-the-kidz/id6447695668"
                  }
                  onClick={(e) => {
                    if (!navigator.onLine) {
                      e.preventDefault();
                      alert(
                        t(
                          "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                        )
                      );
                    }
                  }}
                >
                  {" "}
                  {t("applications")}
                </a>
              </li>
            )}
            <li className="item">
              <Link
                to="termsandconditions"
                onClick={(e) => {
                  if (!navigator.onLine) {
                    e.preventDefault();
                    alert(
                      t(
                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                      )
                    );
                  }
                }}
              >
                {t("termsAndConditions")}
              </Link>
            </li>
            <li className="item">
              <Link
                to="privacypolicy"
                onClick={(e) => {
                  if (!navigator.onLine) {
                    e.preventDefault();
                    alert(
                      t(
                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                      )
                    );
                  }
                }}
              >
                {t("privacyPolicy")}
              </Link>
            </li>
          </ul>
          <ul className="main-footer__social">
            <li className="item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/profile.php?id=100089472698973&mibextid=LQQJ4d"
                onClick={(e) => {
                  if (!navigator.onLine) {
                    e.preventDefault();
                    alert(
                      t(
                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                      )
                    );
                  }
                }}
              >
                <img src={fbIcon} alt="facebook-link" />
              </a>
            </li>
            <li className="item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/amusethekidz?s=11&t=VAdxUp3THKfs9_p2DaNnyA"
                onClick={(e) => {
                  if (!navigator.onLine) {
                    e.preventDefault();
                    alert(
                      t(
                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                      )
                    );
                  }
                }}
              >
                <img src={twIcon} alt="twitter-link" />
              </a>
            </li>
            <li className="item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/amusethekidz?igshid=YmMyMTA2M2Y="
                onClick={(e) => {
                  if (!navigator.onLine) {
                    e.preventDefault();
                    alert(
                      t(
                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                      )
                    );
                  }
                }}
              >
                <img src={instaIcon} alt="instagram-link" />
              </a>
            </li>
            <li className="item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tiktok.com/@amusethekidz?_t=8a5j0NVKGij&_r=1"
                onClick={(e) => {
                  if (!navigator.onLine) {
                    e.preventDefault();
                    alert(
                      t(
                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                      )
                    );
                  }
                }}
              >
                <img src={tiktok} alt="tiktok-link" />
              </a>
            </li>
            {/* <li className='item'>`
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.tiktok.com/@amusethekidz?_t=8a5j0NVKGij&_r=1'
              >
                <img src={ytIcon} alt='tiktok-link' />
              </a>
            </li> */}
          </ul>
          <p className="main-footer__rights">
            {t("rights", { year: new Date().getFullYear() })}
          </p>
        </Container>
      </footer>
    );
  }
};

export default Footer;
