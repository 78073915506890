export const LANG_PARAMS = {
  signin: "signin",
  signup: "signup",
  mobileNumber: "mobileNumber",
  password: "Password",
  forgetPassword: "forgetPassword",
  dontHaveAccount: "dontHaveAccount",
  subscribeNow: "subscribeNow",
  name: "name",
  email: "email",
  mobile: "mobile",
  confirmPassword: "confirmPassword",
  age: "age",
  gender: "gender",
  alreadySubscribe: "alreadySubscribe",
  provideName: "provideName",
  providePhoneNumber: "providePhoneNumber",
  provideEmail: "provideEmail",
  providePassword: "Please, provide your password.",
  passwordLength: "passwordLength",
  provideConfirmPassword: "provideConfirmPassword",
  provideValidEmail: "provideValidEmail",
  confirmPasswordDoesntMatch: "confirmPasswordDoesntMatch",
  home: "home",
  contactUs: "contactUs",
  applications: "applications",
  // FIXME: fix this part
  termsAndConditions: "termsAndConditions",
  privacyPolicy: "Privacy Policy",
  rights: "All rights reserved © Amuse the Kidz 2021",
  forgetPasswordHeader: "Forget Password ?",
  changePasswordHeader: "Change Password",
  enterMobileNumberToRecieveOTP:
    "Enter your mobile number to receive an OTP code",
  sendCode: "Send Code",
  rememberPassword: "Remember Password?",
  signinNow: "Sign In Now!",
  verifyOtpCode: "OTP Code Verification",
  enterOtpNumber: "Enter the OTP code that you recieved in your mobile number",
  otpCodeError: "OTP code is incorrect",
  didntGetCode: "Didn't get the code? ",
  resendCode: "Resend Code",
  personalInformation: "Personal Information",
  saveChanges: "Save Changes",
  chooseWhoWatchNow: "Choose who is watching now",
  manageYourAccount: "manageYourAccount",
  selectAvatar: "selectAvatar",
  pay: "pay",
  personalInfoEditSuccess: "personalInfoEditSuccess",
  accountCreatedSuccess: "accountCreatedSuccess",
  confirmCode: "confirmCode",
  wishList: "wishList",
  subscribeNowWithout: "subscribeNowWithout",
  seeAll: "seeAll",
  manageYourAccounts: "manageYourAccounts",
  Add: "Add",
  Parent: "Parent",
  Kid1: "Kid1",
  Kid2: "Kid2",
  myList: "myList",
  Send: "Send",
  YourMessage: "Your Message",
  EnterYourMobileNumber: " please, enter your mobile number",
  ProvideAtLeastTenNumbers: "provide at least 11 numbers",
  MaximumNumbers16: "please, maximum 16 numbers ",
  resetPassword: "Reset Password",
  confirm: "confirm",
  passwordResetedSuccessfully: "passwordResetedSuccessfully",
  parentalControlCode: "parentalControlCode",
  createParentalCode: "createParentalCode",
  enterParentalCode: "enterParentalCode",
  save: "Save",
  parentalCodeError: "Parental Code Error",
  pinCodeCreatedSuccess: "Parental Code Created Successfully",
  pinUpdateFaild: "Update Faild",
  successPinCode: "Success Parental Code",
  errorPinCode: "Error Parental Code",
  verify: "Verify Code",
  changePassword: "Change Password",
  changePersonalInformation: "Change Personal Information",
  parentalCode: "Parental Code",
  history: "History",
  mySubscription: "My Subscription",
  oldPassword: "Old Password",
  newPassword: "New Password",
  watchNow: "Watch Now",
  provideNewPassword: "Provide new password",
  editFourDigitCode: "Edit 4 digital code",
  pinCodeUpdatedSuccess: "PinCode updated successfully",
  noChanges: "No Changes",
  provideChildAge: "Provide child age",
  ageMustBePlus18: "Age must not be less than 18 ",
  provideChildName: "Please, enter your name",
  fieldDoesNotAcceptNumbers: "Field dose not accept numbers",
  accountCreatedSuccessfully: "Account created successfully",
  addChild: "Add Child",
  chooseLanguage: "Choose Language",
  cast: "Cast",
  movieParts: "Movie Parts",
  episods: "Episodes",
  userDataUpdatedSuccessfully: "User’s data updated successfully",
  datasentSuccessfully: "datasentSuccessfully",
  // FIXME: end of fix
  search: "search",
  manageAccount: "manageAccount",
  switchProfile: "switchProfile",
  profile: "Profile Settings",
  logout: "Log Out",
  audio: "audio",
  audios: "audios",
  subtitles: "subtitles",
  subtitle: "subtitle",
  quality: "quality",
  volume: "volume",
  off: "off",
  default: "default",
  tvshow: "tvshow",
  movies: "movies",
  anime: "anime",
  tvProgram: "tvProgram",
  funStore: "funStore",
  filter: "filter",
  myDownloads: "My Downloads",
};
