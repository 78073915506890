import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, Button, InputNumber } from "antd";
import { Container } from "react-bootstrap";
import { getAllGenders } from "modules/Authentications/network";
import { useTranslation } from "react-i18next";
import InputField from "./components/InputField";
import { editUserData } from "modules/Authentications/network";
import { toastError, toastSuccess } from "helpers/toasters";
import { changeAuthData } from "store/authorization";
import InputPhone from "./components/InputPhone";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";

function PersonalInformation() {
  const { authorization } = useSelector((state) => state.auth);

  const [name, setName] = useState(authorization?.name);
  const [email, setEmail] = useState(authorization?.email);
  const [isChanged, updateIsChanged] = useState(false);
  const [phoneValues, updatePhoneValues] = useState({
    code: null,
    phone: null,
    short: "EG",
  });
  const [age, setAge] = useState(authorization?.age);
  const [errorMessage, setPhoneErrorMessage] = useState(null);
  const [genders, setGenders] = useState([]);
  const [isLoaded, updateIsLoaded] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [loading, setLoading] = useState(false);
  const { lang } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.validateFields();
  }, [i18n.language]);

  useEffect(() => {
    updatePhoneValues({
      ...phoneValues,
      phone: authorization?.phone,
    });
    setAge(authorization?.age);
    setEmail(authorization?.email);
    setName(authorization?.name);
    setTimeout(() => {
      updateIsLoaded(true);
    }, 100);
  }, [authorization]);

  useEffect(() => {
    let phoneSpan = document.querySelector(
      "#root > section > div > form > div.PersonalInfo__inputs > div:nth-child(3) > div > div > div > div > div > span > input"
    );
    phoneSpan?.setAttribute("disabled", "true");
    let codeSpan = document.querySelector(
      "#root > section > div > form > div.PersonalInfo__inputs > div:nth-child(3) > div > div > div > div > div > span > span > span > div"
    );
    codeSpan?.setAttribute("active", "false");
  }, []);
  useEffect(() => {
    getAllGenders(
      lang,
      (success) => {
        if (success.isSuccess) {
          setGenders(success.data);
          const filteredGender = success.data.filter(
            (gender) =>
              gender.name ===
              (authorization?.gender || authorization?.genderName)
          )[0].id;
          setSelectedGender(filteredGender);
        }
      },
      (fail) => {}
    );
  }, [lang, authorization?.genderName]);
  const getErrorMessage = (value) => {
    setPhoneErrorMessage(null);
  };
  const getPhoneValues = (values) => {
    updatePhoneValues(values);
    updateIsChanged(true);
  };
  function onFinish(values) {
    if (!errorMessage) {
      setLoading(true);
      const payload = {
        "name.en": values.name,
        "name.ar": values.name,
        email: values.email,
        phone: phoneValues.phone,
        ...(age && { age }),
        ...(selectedGender && { genderId: selectedGender }),
      };
      editUserData(
        payload,
        (success) => {
          setLoading(false);
          if (success.isSuccess) {
            dispatch(
              changeAuthData({
                name: values?.name ? values.name : null,
                email: values?.email ? values?.email : null,
                phone: phoneValues?.phone ? phoneValues.phone : null,
                age: values?.age ? values.age : null,
                genderName: selectedGender
                  ? genders?.filter(
                      (gender) => gender?.id === selectedGender
                    )[0].name
                  : null,
              })
            );
            toastSuccess(t("personalInfoEditSuccess"));

            navigate(ROUTES.ACCOUNT_PROFILE, { replace: true });
          } else {
          }
        },
        (fail) => {
          setLoading(false);
          if (fail.data.err_msg) toastError(fail.data.err_msg);
          else toastError();
        }
      );
    }
  }
  const preventAge = (e) => {
    const keyCode = e.which;
    const keyValue = String.fromCharCode(keyCode);

    const numericRegex = /^[0-9]$/;

    if (!keyValue.match(numericRegex)) {
      e.preventDefault();
    }
  };

  const onValuesChange = (e) => {
    updateIsChanged(true);
  };
  const preventNumbers = (e) => {
    const allowedChar = ["ط", "د", "ظ", " "];
    if (!e.code.includes("Key") && !allowedChar.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <section className="auth main-section PersonalInfo">
      <Container>
        <div className="text-blue-header PersonalInfo__title mt-4 ">
          {t("personalInformation")}
        </div>
        <Form
          ref={formRef}
          // key={isLoaded}
          className="PersonalInfo__form wd-100 mt-4 "
          scrollToFirstError={true}
          initialValues={{
            name,
            email,
            mobile: phoneValues.code + phoneValues.phone,
            age,
            selectedGender,
          }}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <div className="PersonalInfo__inputs  ">
            <div className="PersonalInfo__input">
              <InputField
                name="name"
                placeholder={t("fullName")}
                onKeyPress={preventNumbers}
                handleOnChange={setName}
                itemClassName="auth__form-item "
                initialVal={name}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const nameArr = value?.split(" ");
                      const regex = /^[a-zA-Z\u0600-\u06FF\s]*$/; // Regular expression to match only letters and spaces

                      if (!value) {
                        return Promise.reject(t("provideName"));
                      } else if (nameArr?.length && value.length) {
                        if (
                          nameArr?.length > 1 &&
                          nameArr[0]?.length !== 0 &&
                          nameArr[1]?.length !== 0
                        ) {
                          if (value.length > 20) {
                            return Promise.reject(t("maximumLength20"));
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(t("provideFullName"));
                      } else if (value.length > 20) {
                        return Promise.reject(t("maximumLength20"));
                      } else if (!regex.test(value)) {
                        return Promise.reject(t("enterValidName"));
                      } else {
                        return Promise.reject(t("provideName"));
                      }
                    },
                  }),
                ]}
              />
            </div>
            <div className="PersonalInfo__input">
              <div className="email">
                <InputField
                  name="email"
                  itemClassName="auth__form-item"
                  placeholder={t("email")}
                  initialVal={email}
                  handleOnChange={setEmail}
                  rules={[
                    {
                      required: false,
                      // message: t("provideEmail"),
                    },
                    {
                      type: "email",
                      message: t("provideValidEmail"),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="PersonalInfo__input ">
              <div className="auth__input-phone ">
                <InputPhone
                  getErrorMessage={getErrorMessage}
                  getPhoneValues={getPhoneValues}
                  phoneValues={phoneValues}
                  itemClassName={"auth__form-item"}
                  disabled={true}
                  key={i18n.language}
                />
              </div>
            </div>
            <div className="PersonalInfo__input">
              <InputField
                name="age"
                itemClassName="auth__form-item"
                maxLength={2}
                initialVal={age}
                handleOnChange={setAge}
                placeholder={t("age")}
                min={0}
                max={99}
                inputClassName="w-100"
                rules={[]}
                onKeyPress={preventAge}
              />
            </div>
            <div className="PersonalInfo__input">
              <Select
                name="selectedGender"
                className="auth__form-select"
                placeholder={t("gender")}
                value={selectedGender}
                onChange={(value) => {
                  setSelectedGender(value);
                  updateIsChanged(true);
                }}
              >
                {genders?.length
                  ? genders?.map(({ id, name }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </div>
          </div>
          <div className="PersonalInfo__SaveChanges  text-center  ">
            <Button
              loading={loading}
              className="button-primary auth__btn"
              htmlType="submit"
              type="primary"
              disabled={!isChanged}
            >
              {t("saveChanges")}
            </Button>
          </div>
        </Form>
      </Container>
    </section>
  );
}

export default PersonalInformation;
