import React, { useState } from "react";
import closeIcon from "../../../../assets/icons/circleXmark.svg";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { enterCouponCode } from "modules/Subscription/network";
import { toastError, toastSuccess } from "helpers/toasters";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";
import Spinner from "modules/Shared/Spinner";

const ProceedWithCodeModal = ({ open, onCancel }) => {
  const { t, i18n } = useTranslation();
  const [code, setCode] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

  const submitCode = () => {
    setLoading(true);
    if (!code) {
      setLoading(false);
      toastError(t("emptycodemessage"));
      return;
    }

    enterCouponCode(
      { code },
      ({ data }) => {
        toastSuccess(data.message);
        setTimeout(() => {
          navigate(ROUTES.HOME);
        }, 2000);
      },
      ({ data }) => {
        setLoading(false);
        toastError(data.err_msg);
      }
    );
  };

  return (
    <Modal
      centered
      open={open}
      width={498}
      onCancel={onCancel}
      className="proceedWithCode-modal modal-container text-center"
      footer={false}
      closeIcon={<img src={closeIcon} alt="icon" className="mb-2" />}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="proceedWithCode-modal_title mb-2">{t("Getyouroffer")}</p>
        <p className="proceedWithCode-modal_message mb-5">
          {t("enteryourcode")}
        </p>
        <div className="proceedWithCode-modal_form">
          <input
            type="text"
            placeholder={t("entercode")}
            className="proceedWithCode-modal_form-input"
            onChange={handleInputChange}
          />
          <Button
            className="button-primary px-1 mw-25 proceedWithCode-modal_form-button"
            loading={loading}
            onClick={submitCode}
            disabled={isSubmitting}
            style={{ position: "relative" }}
          >
            {t("proceed")}

            {/* {isSubmitting && (
              <Spinner
                height={20}
                width={20}
                submitButton
                isNotFullPage={true}
              />
            )} */}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProceedWithCodeModal;
