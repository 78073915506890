import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Form, Button } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import InputField from "../components/InputField";
import { resetPassword } from "../network";
import ROUTES from "constants/_routes";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (showError) {
      formRef.current.validateFields();
    }
  }, [i18n.language, showError]);

  useEffect(() => {
    if (!location?.state?.phone) navigate(ROUTES.FORGET_PASSWORD);
  }, [location?.state?.phone, navigate]);

  function onSubmit(e) {
    setShowError(false);
    // e.preventDefault();
    const data = {
      password: confirmPassword,
      phone: location.state.phone,
      code: location.state.code,
    };

    if (confirmPassword?.length) {
      setLoading(true);
      resetPassword(
        data,
        (success) => {
          setLoading(false);
          if (success.isSuccess) {
            toastSuccess(t("passwordResetedSuccessfully"));
            navigate(ROUTES.SIGNIN, { replace: true });
          } else toastError(success.error);
        },
        (fail) => {
          setLoading(false);
          if (fail.data) toastError(fail.data.err_msg);
          else toastError();
        }
      );
    }
  }

  return (
    <section className="main-section  addNewPassword">
      <Container>
        {/* <div className="addNewPassword__container"> */}
        <div className="auth__title text-blue-header addNewPassword__center">
          {t("resetPassword")}
        </div>
        <Form
          ref={formRef}
          className="addNewPassword__Form "
          onFinish={onSubmit}
          onFinishFailed={() => {
            setShowError(true);
          }}
        >
          <div className="addNewPassword__Input">
            <InputField
              name={"password"}
              itemClassName="auth__form-item"
              placeholder={t("newPassword")}
              initialVal={password}
              handleOnChange={setPassword}
              rules={[
                {
                  required: true,
                  message: t("providePassword"),
                },
                {
                  min: 6,
                  message: t("passwordLength"),
                },
              ]}
            />
          </div>
          <div className="addNewPassword__Input">
            <InputField
              name="confirm-password"
              itemClassName={"auth__form-item"}
              placeholder={t("confirmNewPassword")}
              initialVal={confirmPassword}
              handleOnChange={setConfirmPassword}
              rules={[
                {
                  required: true,
                  message: t("provideConfirmPassword"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("confirmPasswordDoesntMatch"));
                  },
                }),
              ]}
              dependencies={["password"]}
            />
          </div>
          <div className="text-center mb-4 mt-4">
            <Button
              loading={loading}
              className="auth__btn button-primary "
              htmlType="submit"
            >
              {t("confirm")}
            </Button>
          </div>
        </Form>
        {/* </div> */}
      </Container>
    </section>
  );
};
export default ResetPassword;
