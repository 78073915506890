import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { Form, Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { changePassword } from "../network";
import { toastError, toastSuccess } from "helpers/toasters";
import InputField from "../components/InputField";
import ROUTES from "constants/_routes";
import WebsiteTitle from "helpers/WebsiteTitle";

function ChangePassword() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  function onSubmit() {
    setShowError(false);
    if (oldPassword?.length && newPassword?.length && confirmPassword?.length) {
      let parentId = auth.authorization.userAccounts.filter(
        (el) => el.isParent === true
      )[0]?.id;
      let data = {
        parentId,
        oldPassword,
        newPassword,
      };
      changePassword(
        data,
        (success) => {
          setLoading(false);
          if (success.isSuccess) {
            toastSuccess(t("passwordChangedSuccessfully"));
            navigate(ROUTES.ACCOUNT_PROFILE);
          }
        },
        (fail) => {
          setLoading(false);
          if (fail.data) toastError(fail.data.err_msg);
        }
      );
    }
  }

  const formRef = useRef(null);

  useEffect(() => {
    if (showError) {
      formRef.current.validateFields();
    }
    setShowError(true);
  }, [i18n.language]);

  return (
    <section className="change-Password main-section">
      <WebsiteTitle title={"Change Password"} />
      <Container className="">
        <div className="change-Password__content   ">
          <div className="auth__blue-title text-blue-header text-center  mb-5">
            {t("changePasswordHeader")}
          </div>
          <Form
            onFinishFailed={() => {
              setShowError(true);
            }}
            onFinish={onSubmit}
            className="change-Password__form  w-100"
            ref={formRef}
          >
            <Row className="d-flex flex-column w-100 ">
              <div className="col-12 col-md-6 w-100 mb-2 change-Password__form-item">
                <InputField
                  name={"old-password"}
                  itemClassName="auth__form-item"
                  placeholder={t("oldPassword")}
                  initialVal={oldPassword}
                  handleOnChange={setOldPassword}
                  rules={[
                    {
                      required: true,
                      message: t("providePassword"),
                    },
                    {
                      min: 6,
                      message: t("passwordLength"),
                    },
                  ]}
                />
              </div>
              <div className="col-12 col-md-6 w-100 mb-2 change-Password__form-item">
                <InputField
                  name={"new-password"}
                  itemClassName="auth__form-item"
                  placeholder={t("newPassword")}
                  initialVal={newPassword}
                  handleOnChange={setNewPassword}
                  rules={[
                    {
                      required: true,
                      message: t("provideNewPassword"),
                    },
                    {
                      min: 6,
                      message: t("passwordLength"),
                    },
                  ]}
                />
              </div>
              <div className="col-12 col-md-6 w-100 mb-2 change-Password__form-item">
                <InputField
                  name="confirm-password"
                  itemClassName={"auth__form-item"}
                  placeholder={t("confirmPassword")}
                  initialVal={confirmPassword}
                  handleOnChange={setConfirmPassword}
                  rules={[
                    {
                      required: true,
                      message: t("provideConfirmPassword"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("new-password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("confirmPasswordDoesntMatch"));
                      },
                    }),
                  ]}
                  dependencies={["password"]}
                />
              </div>
              <div className="col-12 col-md-6 w-100 mb-2  text-center">
                <Button
                  loading={loading}
                  className="auth__btn button-primary  "
                  htmlType="submit"
                >
                  {t("confirm")}
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </Container>
    </section>
  );
}

export default ChangePassword;
