import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource, postResource } from "services";

export function getVideoDetails(
  { lang, videoHeaderId, isSeries, videoId, userAccountId },
  onSuccess,
  onFail
) {
  const queryParams = {
    videoHeaderId,
    isSeries,
    videoId,
    lang,
    ...(userAccountId && { userAccountId }),
  };
  const path = PATHS(PATHS_PARAMS.getVideoDetails, lang, queryParams);
  getResource(path, onSuccess, onFail);
}
export function getCastsData(
  { lang, videoHeaderId, isSeries, videoId, partId },
  onSuccess,
  onFail
) {
  const queryParams = {
    videoHeaderId,
    isSeries,
    videoId,
    lang,
    partId,
  };
  const path = PATHS(PATHS_PARAMS.getCastsData, lang, queryParams);
  getResource(path, onSuccess, onFail);
}
export function getAudiosAndSubtitle(
  { lang, videoHeaderId, isSeries, videoId, partId },
  onSuccess,
  onFail
) {
  const queryParams = {
    videoHeaderId,
    isSeries,
    videoId,
    lang,
    partId,
  };
  const path = PATHS(PATHS_PARAMS.getAudiosAndSubtitle, lang, queryParams);
  getResource(path, onSuccess, onFail);
}
export function getRelatedSeriesSessons(
  { lang, videoHeaderId, videoId },
  onSuccess,
  onFail
) {
  const seriesSessonsParams = {
    videoHeaderId,
    videoId,
    lang,
  };
  const path = PATHS(PATHS_PARAMS.getSeriesSessons, lang, seriesSessonsParams);
  getResource(path, onSuccess, onFail);
}
export function getRelatedMovieParts(
  { lang, videoHeaderId, videoId },
  onSuccess,
  onFail
) {
  const moviesPartsParams = {
    videoHeaderId,
    videoId,
  };
  const path = PATHS(PATHS_PARAMS.getMovieParts, lang, moviesPartsParams);
  getResource(path, onSuccess, onFail);
}
export function checkVideoFree(videoId, onSuccess, onFail) {
  const queryParams = {
    videoId,
  };
  const path = PATHS(PATHS_PARAMS.checkVideoIsFree, queryParams);
  getResource(path, onSuccess, onFail);
}
export function getUserAccounts({ userId, lang }, onSuccess, onFail) {
  const params = {
    userId,
  };
  const path = PATHS(PATHS_PARAMS.getUserAccounts, lang, params);
  getResource(path, onSuccess, onFail);
}
export function continueWatching(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.continueWatching);
  postResource(path, data, onSuccess, onFail);
}
export function sendVideoViews(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.sendVideoViews);
  postResource(path, data, onSuccess, onFail);
}
export function addVideoViewNumber(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.addVideoViewNumber);
  postResource(path, data, onSuccess, onFail);
}
export function addVideoToDownloads(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.addUserDownloads);
  postResource(path, data, onSuccess, onFail);
}
export function getVideoToDownloads(params, onSuccess, onFail) {
  const lang = false;
  const path = PATHS(PATHS_PARAMS.addUserDownloads, lang, params);
  getResource(path, onSuccess, onFail);
}
export function deleteVideoFromDownloads(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.addUserDownloads);
  postResource(path, data, onSuccess, onFail);
}
