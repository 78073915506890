import ROUTES from "constants/_routes";
import { forwardRef, useEffect, useRef } from "react";
import { Button, Input, Dropdown as AntDropdown, Space } from "antd";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { baseUrl } from "services";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import { LANG_PARAMS } from "localization/translation-params";
import GifPlayer from "react-gif-player";
import giftIcon from "assets/images/giftIcon.gif";

import localizationAr from "assets/icons/ar.svg";
import localizationEn from "assets/icons/localization-en.svg";
import amuseLogo from "assets/images/amuse-logo.svg";

import search from "assets/icons/search.svg";
import searchWhite from "assets/icons/search-white.svg";

const MainHeaderMobileScreen = forwardRef(
  (
    {
      searchBarStatus,
      toggleSearchBarStatus,
      searchResults,
      onSearchMovieClick,
      searchValue,
      setSearchValue,
      // getPopupContainer,
      whiteNavbar,
      avatarImg,
      toggleLogoutModalVisible,
      openGiftModalHandler,
      changeLangAction,
      handleToggle,
      handleCollapseMobileNavbar,
      goTo,
      handleSubscribeBtn,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const { lang } = useSelector((state) => state.lang);
    const { authorization } = useSelector((state) => state.auth);

    const isAuth = authorization?.token;
    const isPaid = authorization?.isPaid?.isPaid;
    const hasPaidBefore = authorization?.isPaid?.hasPaidBefore;

    useEffect(() => {
      if (searchBarStatus) {
        inputRef?.current.focus();
      }
    }, [searchBarStatus]);

    return (
      <Container fluid className="custom-container main-header__mobile">
        {!searchBarStatus && (
          <Navbar.Brand href="#home">
            <Link to={ROUTES.HOME}>
              <img src={amuseLogo} alt="amuse the kids" height="47px" />
            </Link>
          </Navbar.Brand>
        )}
        <div className="amuse-nav__search-gift-mobile " ref={ref.wrapperRefMob}>
          <div
            className={` item d-flex mx-3 ${
              searchBarStatus ? "flex-1" : undefined
            } `}
          >
            <AntDropdown
              open={searchValue?.length > 0 && searchBarStatus}
              menu={{
                items: searchResults,
                onClick: onSearchMovieClick,
              }}
              overlayClassName="navbar-search-dropdown py-2"
              getPopupContainer={() => ref?.wrapperRefMob.current}
            >
              <div
                className={`${
                  searchBarStatus
                    ? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
                    : ""
                }`}
              >
                <Input
                  ref={inputRef}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  type="search"
                  className={`${searchBarStatus ? "d-block" : "d-none"}`}
                />
                {/* <img
                  src={whiteNavbar || searchBarStatus ? search : searchWhite}
                  className="search-icon"
                  alt="search in amuse"
                  role="button"
                  onClick={() => toggleSearchBarStatus(true)}
                /> */}
                <button
                  onClick={() => toggleSearchBarStatus(true)}
                  style={{ background: "none", border: "none", padding: 0 }}
                >
                  <img
                    src={whiteNavbar || searchBarStatus ? search : searchWhite}
                    className="search-icon"
                    alt="search in amuse"
                  />
                </button>
              </div>
            </AntDropdown>
          </div>
          {!searchBarStatus && (
            <div className="item">
              <div onClick={openGiftModalHandler}>
                <GifPlayer gif={giftIcon} autoplay />
              </div>
            </div>
          )}
        </div>
        <Navbar.Toggle
          // expanded={expanded}
          aria-controls="responsive-navbar-nav "
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          // onClick={toggleNavbar}

          ref={ref.collapseBtn}
          // onClick={() => {
          //   handleCollapseMobileNavbar();
          //
          //   setIsCollapsedInSmallScreen((prev) => !prev);
          // }}
        />

        <Navbar.Collapse id="responsive-navbar-nav" ref={ref.collapseArea}>
          <div
            className="amuse-nav__menus-mobile"
            ref={ref.collapseMobileNavbarRef}
          >
            <ul
              className={`amuse-nav__ul-end-mobile py-4 ${
                searchBarStatus ? "d-flex flex-1" : ""
              }`}
            >
              {authorization?.online?.id && (
                <li className="item">
                  <Dropdown
                    className={`amuse-nav__menu ${
                      whiteNavbar ? "white-nav-mobile" : ""
                    }`}
                  >
                    <Dropdown.Toggle
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="item">
                        <img
                          className="amuse-nav__avatar"
                          src={addTokenToMedia(baseUrl + avatarImg)}
                          alt="avatar"
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                              )
                            );
                            return;
                          } else {
                            goTo(ROUTES.WATCHERS);
                          }
                        }}
                      >
                        {t(LANG_PARAMS.switchProfile)}
                      </Dropdown.Item>

                      {authorization?.online?.isParent && (
                        <Dropdown.Item
                          onClick={(e) => {
                            if (!navigator.onLine) {
                              e.preventDefault();
                              alert(
                                t(
                                  "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                                )
                              );
                              return;
                            } else {
                              goTo(ROUTES.ACCOUNT_PROFILE);
                            }
                          }}
                        >
                          {t("profile")}
                        </Dropdown.Item>
                      )}
                      {/* DOWNLOADS: */}
                      {/* <Dropdown.Item
                    className={`${
                      lang === "ar" ? "dropdown-menu-item-ar" : ""
                    }`}
                    onClick={() => navigate(ROUTES.MY_DOWNLOADS)}
                  >
                    {t("myDownloads")}
                  </Dropdown.Item> */}

                      <Dropdown.Item
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                              )
                            );
                            return;
                          } else {
                            document.body.style.overflow = "hidden";
                            toggleLogoutModalVisible(true);
                          }
                        }}
                      >
                        {t("logout")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}

              {/* subscription */}
              <li className="item">
                {!(hasPaidBefore && isPaid) && (
                  <Button
                    className="amuse-nav__btn button-primary"
                    onClick={handleSubscribeBtn}
                  >
                    {
                      hasPaidBefore
                        ? t("renewSubscription") // hasPaidBefore is true and isPaid is false
                        : t("subscribeNowWithout") // hasPaidBefore is false and isPaid is either true or false
                    }
                  </Button>
                )}
              </li>

              {isAuth ? (
                <li className="item">
                  <Link
                    to={ROUTES.MY_LIST}
                    onClick={(e) => {
                      if (!navigator.onLine) {
                        e.preventDefault();
                        alert(
                          t(
                            "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                          )
                        );
                        return;
                      } else {
                        handleToggle();
                        handleCollapseMobileNavbar();
                      }
                    }}
                  >
                    {t("wishlist")}
                  </Link>
                </li>
              ) : (
                // signin
                <li className="item">
                  <Link
                    to={ROUTES.SIGNIN}
                    onClick={(e) => {
                      if (!navigator.onLine) {
                        e.preventDefault();
                        alert(
                          t(
                            "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                          )
                        );
                        return;
                      } else {
                        handleToggle();
                        handleCollapseMobileNavbar();
                      }
                    }}
                  >
                    <Button className="amuse-nav__btn button-secondary-mobile">
                      {t("signin")}
                    </Button>
                  </Link>
                </li>
              )}
              <li className="item ">
                <Link
                  to={ROUTES.HOME}
                  onClick={(e) => {
                    if (!navigator.onLine) {
                      e.preventDefault();
                      alert(
                        t(
                          "YouareCurrentlyOfflinePleaseCheckYourInternetConnection"
                        )
                      );
                      return;
                    } else {
                      handleToggle();
                      handleCollapseMobileNavbar();
                    }
                  }}
                >
                  {t("home")}
                </Link>
              </li>
              {/* lang */}

              <li className="item">
                <Space size={10}>
                  {" "}
                  <span>Language</span>
                  {lang === "en" ? (
                    <img
                      className={`${lang === "en"}`}
                      src={localizationAr}
                      alt="localization-ar"
                      role={"button"}
                      onClick={changeLangAction}
                    />
                  ) : (
                    <img
                      src={localizationEn}
                      alt="localization-en"
                      role={"button"}
                      onClick={changeLangAction}
                    />
                  )}
                </Space>
              </li>
            </ul>
          </div>
        </Navbar.Collapse>
      </Container>
    );
  }
);

export default MainHeaderMobileScreen;
