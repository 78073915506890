import React, { useState } from "react";
import { Button, Radio } from "antd";
import fawry from "../../../../assets/icons/fawry.svg";
import mastercard from "../../../../assets/icons/mastercard.svg";
import visa from "../../../../assets/icons/visa.svg";
import meeza from "assets/icons/meeza.png";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fawryPay, payByCreditcard } from "modules/Subscription/network";
import { useSelector } from "react-redux";
import FawryModal from "../FawryModal/FawryModal";
import ROUTES from "constants/_routes";
import { useTranslation } from "react-i18next";
import { toastError } from "helpers/toasters";
function Payment() {
  const [paymentValue, setPaymentValue] = useState(null);
  const [selectedPackage, updateSelectedPackage] = useState(null);
  const [isFawryPayModalVis, updateIsFawryPayModalVis] = useState(false);
  const [fawryDetails, updateFawryDetails] = useState(null);
  const { lang } = useSelector((state) => state.lang);
  const [loading, updateLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const packageParam = location.search.split("packageId=").length
    ? location.search.split("packageId=")[1]
    : null;
  const paymentsList = [
    {
      list: [
        { img: visa, id: "visa" },
        { img: mastercard, id: "mastercard" },
        { img: meeza, id: "meeza" },
      ],
    },
    { img: fawry, id: "fawry" },
  ];

  useEffect(() => {
    updateSelectedPackage(packageParam);
  }, [packageParam]);

  const onPaymentChange = (e) => {
    setPaymentValue(e.target.value);
  };

  const handlePay = () => {
    if (paymentValue === "fawry") {
      let data = {
        packageId: selectedPackage,
        lang,
      };
      updateLoading(true);
      fawryPay(
        data,
        data,
        (success) => {
          if (success.isSuccess) {
            updateIsFawryPayModalVis(true);
            updateFawryDetails(success.data);
          } else {
            toastError(success.err_msg);
          }
          updateLoading(false);
        },
        (fail) => {
          if (fail.data?.err_msg) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
          updateLoading(false);
        }
      );
    } else {
      let data = { packageId: selectedPackage };
      updateLoading(true);
      payByCreditcard(
        data,
        (success) => {
          if (success.isSuccess) {
            window.location.href = success.data.iframe;
          } else {
            toastError(success.err_msg);
          }
          updateLoading(false);
        },
        (fail) => {
          if (fail.data?.err_msg) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
          updateLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="payment text-center">
      <h5 className="main-title my-4 text-white">{t("proceedToPayment")}</h5>
      <div className="d-flex flex-column justify-content-center  payment-box my-4 align-items-center">
        <div className="sub-title  mx-auto mx-md-2 mb-4 text-white">
          {t("choosePaymentMethod")}
        </div>
        <div>
          <Radio.Group onChange={onPaymentChange} value={paymentValue}>
            {paymentsList.map((payment) => {
              return (
                <Radio
                  className={`mx-2 d-flex align-items-center ${
                    payment.id === "fawry" && "mt-4"
                  }`}
                  value={payment.id}
                  key={payment.id}
                >
                  {payment.list ? (
                    <div className="d-flex align-items-center">
                      {payment.list.map((paymentCard, index) => {
                        return (
                          <span
                            className="d-flex"
                            style={{ display: "inline-block", height: "25px" }}
                          >
                            <img src={paymentCard.img} alt="payment" />
                            {payment.list.length - 1 !== index && (
                              <div className="or-text mx-4 fw-600"></div>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <img
                      src={payment.img}
                      alt="payment"
                      className="my-2"
                      style={{ display: "inline-block", height: "25px" }}
                    />
                  )}
                </Radio>
              );
            })}
          </Radio.Group>
        </div>
      </div>
      <Button
        disabled={paymentValue === null}
        className="button-primary my-4 w-20"
        onClick={handlePay}
        loading={loading}
      >
        {t("pay")}
      </Button>
      <FawryModal
        isModalVisible={isFawryPayModalVis}
        onCancel={() => {
          updateIsFawryPayModalVis(false);
          navigate(ROUTES.HOME);
        }}
        fawryDetails={fawryDetails}
      />
    </div>
  );
}

export default Payment;
