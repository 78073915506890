import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import InputPhone from "../components/InputPhone";
import { forgetPassword } from "../network";
import { toastError } from "helpers/toasters";
import ROUTES from "constants/_routes";
import "flagpack/dist/flagpack.css";

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [phone, setPhone] = useState("");
  // eslint-disable-next-line
  const [code, setCode] = useState("");
  // eslint-disable-next-line
  const [showError, setShowError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isSubmitted, updateIsSubmitted] = useState(false);
  const [phoneValues, setPhoneValues] = useState({
    code: "20",
    phone: "",
    short: "EG",
  });
  const navigate = useNavigate();

  function onSubmit() {
    setShowError(true);
    updateIsSubmitted(true);

    var ph;
    if (phoneError === "") {
      setLoading(true);
      const codeIsEndWithZero = phoneValues?.code.toString().endsWith("0");
      const phoneIsEndWithZero = phoneValues.phone.startsWith("0");
      if (codeIsEndWithZero && phoneIsEndWithZero) {
        ph = phoneValues.phone.slice(1);
      } else {
        ph = phoneValues.phone;
      }
      const fullPhoneNumber = phoneValues.code + ph;
      forgetPassword(
        { phone: fullPhoneNumber },
        (success) => {
          setLoading(false);
          const { isSuccess } = success;
          if (isSuccess) {
            navigate(ROUTES.OTP, {
              state: { phone: fullPhoneNumber },
              replace: true,
            });
          } else {
            toastError(success.err_msg);
          }
        },
        (fail) => {
          setLoading(false);
          if (fail.data.err_msg) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        }
      );
    }
  }

  const getErrorMessage = (value) => {
    setPhoneError(value);
  };

  const getPhoneValues = (values) => {
    setPhoneValues(values);
  };

  return (
    <section className="auth forget-pass main-section">
      <Container className="mt-4">
        <Form
          onSubmitCapture={onSubmit}
          className="forget-pass__form auth__form mt-4"
        >
          <div>
            <div className="auth__blue-title text-blue-header text-center mt-4">
              {t("forgetPasswordHeader")}
            </div>
            <div className="auth__blue-title text-blue-header text-center my-4 f-21">
              {t("forgetPasswordSubHeader")}
            </div>
          </div>
          <div className="auth__input-phone my-2">
            <InputPhone
              itemClassName={"auth__form-item"}
              getErrorMessage={getErrorMessage}
              getPhoneValues={getPhoneValues}
              phoneValues={phoneValues}
              mode="initial"
              isSubmitted={isSubmitted}
              key={i18n.language}
            />
          </div>
          <div className="text-center forget-pass__btn">
            <Button
              loading={loading}
              className="button-primary"
              htmlType="submit"
            >
              {t("sendCode")}
            </Button>
            <div className="auth__sub-text ">
              <span>{t("rememberPassword")}</span>
              <Link to={ROUTES.SIGNIN}>{t("signin")}!</Link>
            </div>
          </div>
        </Form>
      </Container>
    </section>
  );
};

export default ForgetPassword;
