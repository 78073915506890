import React, { useEffect, useRef, useState } from "react";
// import "./styles.css";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CustomSelect = ({ selected, options, dropdownChangeHanlder }) => {
  /**
   * @options will be in this format   
   * const options = [
    { label: "React", value: "react" },
    { label: "Angular", value: "angular" },
    { label: "Vue", value: "vue" },
  ];
   */

  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const { lang, dir } = useSelector((state) => state.lang);

  useEffect(() => {
    // Add an event listener to the document to close the dropdown when clicking outside
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="Customselect" ref={dropdownRef}>
      <div
        className="Customselect-btn"
        onClick={() => setIsActive((prev) => !prev)}
      >
        {t(selected) || (
          <p className="Customselect-placeholder">{t("choosetheoperator")}</p>
        )}
        <CaretDownOutlined style={{ fontSize: "24px", color: "#666666" }} />
      </div>
      {isActive && (
        <div className="Customselect-content">
          {options.map((option) => (
            <div
              key={option.value}
              className="Customselect-item"
              style={{ textAlign: dir === "ltr" ? "left" : "right" }}
              onClick={(e) => {
                dropdownChangeHanlder(option.label, option.value);
                setIsActive(false);
              }}
            >
              {t(option.label)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
