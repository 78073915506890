import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, Select, Button, Row, Col } from "antd";
import { Container } from "react-bootstrap";
import { getAllGenders, signup } from "modules/Authentications/network";
import { toastError, toastSuccess } from "helpers/toasters";
import { useTranslation } from "react-i18next";
import InputField from "./components/InputField";
import InputPhone from "./components/InputPhone";
import { changeAuthData, login } from "store/authorization";
import ROUTES from "constants/_routes";
import { v4 as uuidv4 } from "uuid";
import { browserName } from "react-device-detect";
//import { onlineUser } from "store/authorization";
import WebsiteTitle from "helpers/WebsiteTitle";
import { useRef } from "react";

function SignUp() {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [age, setAge] = useState(null);
  const [genders, setGenders] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [phoneError, setPhoneError] = useState(true);
  const [phoneValues, setPhoneValues] = useState({
    code: "20",
    phone: "",
    short: "EG",
  });
  const [isSubmitted, updateIsSubmitted] = useState(false);
  const { lang } = useSelector((state) => state.lang);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllGenders(
      lang,
      (success) => {
        if (success.isSuccess) {
          setGenders(success.data);
        }
      },
      (fail) => {}
    );
  }, [lang]);

  function submit(values) {
    setShowError(false);
    var ph;
    if (!phoneError) {
      setLoading(true);
      const codeIsEndWithZero = phoneValues?.code.toString().endsWith("0");
      const phoneIsEndWithZero = phoneValues.phone.startsWith("0");
      if (codeIsEndWithZero && phoneIsEndWithZero) {
        ph = phoneValues.phone.slice(1);
      } else {
        ph = phoneValues.phone;
      }
      if (name && password && password === confirmPassword) {
        setLoading(true);
        const payload = {
          "name.en": name,
          "name.ar": name,
          email,
          password,
          phone: phoneValues.code + ph,
          ...(values.age && { age: values.age }),
          ...(selectedGender && { genderId: selectedGender }),
          firebaseToken: "there's no firebase token",
        };

        // if (!email) {
        //   delete payload.email;
        // }
        signup(
          payload,
          (success) => {
            setLoading(false);
            if (success.isSuccess) {
              let deviceId = uuidv4();
              let SignupData = { ...success.data, deviceId, browserName };
              dispatch(login(SignupData));
              dispatch(changeAuthData({ status: "signUp" }));
              toastSuccess(success.message);
              setTimeout(() => {
                navigate(ROUTES.WATCHERS);
              }, 0);
              navigate(ROUTES.WATCHERS);

              // ? make the parent as a online users -> on his first registeration.
              // let parent = success.data?.userAccounts?.filter(
              // 	(el) => el.isParent === true
              // )[0];
              // dispatch(onlineUser(parent));
            }
          },
          (fail) => {
            setLoading(false);
            if (fail.data) toastError(fail.data.err_msg);
            else toastError();
          }
        );
      }
    }
  }
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.code.includes("Key")) {
      e.preventDefault();
    }
  };
  const getErrorMessage = (value) => {
    setPhoneError(value);
  };
  const getPhoneValues = (values) => {
    setPhoneValues(values);
  };

  const preventNumbers = (e) => {
    const allowedChar = ["ط", "د", "ظ", "ذ", "ج", "ك", "ز", "و", " "];
    if (!e.code.includes("Key") && !allowedChar.includes(e.key)) {
      e.preventDefault();
    }
  };

  const formRef = useRef(null);

  useEffect(() => {
    if (showError) {
      formRef.current.validateFields();
    }
  }, [i18n.language, showError]);

  return (
    <section className="auth main-section">
      <WebsiteTitle title={"Sign up"} />
      <Container>
        <div className="auth__title text-blue-header">{t("signup")}</div>
        <Form
          ref={formRef}
          className="auth__form"
          autoComplete="off"
          action="post"
          onFinish={submit}
          onFinishFailed={() => {
            setShowError(true);
          }}
        >
          <div className="auth__form--w50-layout">
            <div className="auth__form__inputs-container">
              <div className="auth__form__inputs-container__input-name">
                <InputField
                  name="name"
                  placeholder={t("fullName")}
                  initialVal={name}
                  onKeyPress={preventNumbers}
                  handleOnChange={setName}
                  itemClassName="auth__form-item"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const nameArr = value?.split(" ");
                        const regex = /^[^0-9]*$/;

                        // Regular expression to match only letters and spaces

                        if (!value) {
                          return Promise.reject(t("provideName"));
                        } else if (nameArr?.length && value.length) {
                          if (
                            nameArr?.length > 1 &&
                            nameArr[0]?.length !== 0 &&
                            nameArr[1]?.length !== 0
                          ) {
                            if (value.length > 20) {
                              return Promise.reject(t("maximumLength20"));
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject(t("provideFullName"));
                        } else if (value.length > 20) {
                          return Promise.reject(t("maximumLength20"));
                        } else if (regex.test(value)) {
                          return Promise.reject(t("enterValidName"));
                        } else {
                          return Promise.reject(t("provideName"));
                        }
                      },
                    }),
                  ]}
                  dependencies={["name"]}
                />
              </div>
              <div className="auth__form__inputs-container__input-email">
                <InputField
                  name="email"
                  autoComplete="off"
                  inputType="email"
                  itemClassName="auth__form-item"
                  placeholder={t("email")}
                  initialVal={email}
                  handleOnChange={setEmail}
                  rules={[
                    {
                      required: false,
                      // message: t("provideEmail"),
                    },
                    {
                      type: "email",
                      message: t("provideValidEmail"),
                    },
                  ]}
                />
              </div>

              <div className="auth__form__inputs-container__input-phone ">
                <InputPhone
                  getErrorMessage={getErrorMessage}
                  getPhoneValues={getPhoneValues}
                  phoneValues={phoneValues}
                  mode="initial"
                  itemClassName={"auth__form-item"}
                  isSubmitted={isSubmitted}
                  key={i18n.language}
                  // autoComplete='new-password'
                />
              </div>
              <div className="auth__form__inputs-container__input-age">
                <InputField
                  name="age"
                  itemClassName="auth__form-item"
                  inputType={"number"}
                  min={0}
                  max={99}
                  initialVal={age}
                  handleOnChange={(value) => {
                    if (value.length < 2) {
                      setAge(value);
                    }
                  }}
                  maxLength={2}
                  placeholder={t("age")}
                  rules={[]}
                  onKeyPress={preventMinus}
                  inputClassName="w-100"
                />
              </div>
              <div className="auth__form__inputs-container__input-password">
                <InputField
                  name={"password"}
                  itemClassName="auth__form-item"
                  placeholder={t("password")}
                  initialVal={password}
                  handleOnChange={setPassword}
                  rules={[
                    {
                      required: true,
                      message: t("providePassword"),
                    },
                    {
                      min: 6,
                      message: t("passwordLength"),
                    },
                  ]}
                  autoComplete="new-password"
                />
              </div>
              <div className="auth__form__inputs-container__input-select-gender">
                <Select
                  className="auth__form-select"
                  placeholder={t("gender")}
                  onChange={(value) => setSelectedGender(value)}
                >
                  {genders?.length
                    ? genders?.map(({ id, name }) => (
                        <Select.Option value={id} key={id}>
                          {name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </div>
              <div className="auth__form__inputs-container__input-confirm-password">
                <InputField
                  name="confirm-password"
                  itemClassName={"auth__form-item"}
                  placeholder={t("confirmPassword")}
                  initialVal={confirmPassword}
                  handleOnChange={setConfirmPassword}
                  rules={[
                    {
                      required: true,
                      message: t("provideConfirmPassword"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("confirmPasswordDoesntMatch"));
                      },
                    }),
                  ]}
                  dependencies={["password"]}
                />
              </div>
            </div>

            <div className="auth__form__submit">
              <Row justify={"center"}>
                <Col
                  xs={{ span: 18 }}
                  sm={{ span: 14 }}
                  md={{ span: 10 }}
                  lg={{ span: 7 }}
                >
                  <Button
                    loading={loading}
                    className="auth__btn button-primary-100vw "
                    htmlType="submit"
                    onClick={() => {
                      updateIsSubmitted(true);
                    }}
                  >
                    {t("signup")}
                  </Button>
                  <div className="auth__sub-text">
                    <span>{t("alreadyHaveAccount")}</span>{" "}
                    <Link to={ROUTES.SIGNIN}>{t("signin")}</Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Container>
    </section>
  );
}

export default SignUp;
